import ClipboardJS from "clipboard";
import { Message } from "element-ui";

//复制文本
(function () {
  const clipboard = new ClipboardJS(".copy");
  clipboard.on("success", function (e) {
    Message({
      type: "success",
      message: "复制成功",
    });
  });

  clipboard.on("error", function (e) {
    Message({
      type: "error",
      message: "复制失败",
    });
  });
})();
