import storage from "./storage";
import { order } from "./dict.js";

/**
 * @param { Any } time
 * @param { Boolean } pattern
 * @returns { String }
 * @desc 解析时间格式
 */
function parseTime(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null;
  }
  const format = pattern || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string" && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    } else if (typeof time === "string") {
      time = time
        .replace(new RegExp(/-/gm), "/")
        .replace("T", " ")
        .replace(new RegExp(/\.[\d]{3}/gm), "");
    }
    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }
    return value || 0;
  });
  return time_str;
}

/**
 * @param { Object } params
 * @returns { Object }
 * @desc 获取非空对象，除了数字0和false以外，所有布尔值为false的都不返回
 */
function getNotEmptyObj(params) {
  const obj = {};
  Object.keys(params).forEach((key) => {
    if (params[key]) {
      obj[key] = params[key];
    } else {
      if (params[key] === 0 || typeof params[key] === "boolean") {
        obj[key] = params[key];
      }
    }
  });
  return obj;
}

/**
 * @param { Object } params 需要转换的对象
 * @param { Array } before before是转换前的key，按顺序一一对应
 * @param { Array } after after是转换后的key
 * @returns { Object }
 * @desc 转换对象的key值
 */
function transObjKey(params, before, after) {
  const obj = {};
  Object.keys(params).forEach((key) => {
    const index = before.findIndex((item) => item === key);
    if (index > -1) {
      obj[after[index]] = params[key];
    } else {
      obj[key] = params[key];
    }
  });
  return obj;
}

/**
 * @param { string } str
 * @returns { boolean }
 * @desc 转换对象的key值
 */
// 使用try-catch去捕捉错误
// 直接使用JSON.parse去转译，并把转译结果判断一下是不是object类型，如果是的话就返回true,否则就返回false,这样就排除了转化后非object的类型，比如"123456789"
function isJSON(str) {
  try {
    if (typeof JSON.parse(str) == "object") {
      return true;
    }
  } catch (e) {}
  return false;
}

/**
 * @param { Object } data 需要转换的blob数据
 * @returns { Object }
 * @desc 转换对象的key值
 */
function blobToObj(data) {
  return new Promise(async function (resolve) {
    const reader = new FileReader();
    reader.readAsText(data, "utf-8");
    reader.onload = function (e) {
      const readerres = reader.result;
      if (readerres.includes("PDF")) {
        resolve();
      } else {
        let parseObj;
        if (isJSON(readerres)) {
          parseObj = JSON.parse(readerres);
        } else {
          parseObj = JSON.parse(JSON.stringify(readerres));
        }
        resolve(parseObj);
      }
    };
  });
}

/**
 * @param { String } attr 脚本属性
 * @desc 加载脚本
 */
function loadScript(attr) {
  const script = document.createElement("script");
  script.type = "text/javacript";
  Object.entries(attr).forEach((item) => {
    script[item[0]] = item[1];
  });
  document.body.appendChild(script);
}

/**
 * @desc 获取用户选择的业务类型对应的数字
 * @returns { Number } num 用户选择的业务类型对应的数字
 */
function getUserBstpNo() {
  const { busiType } = order;
  let num;
  const userBstpSelected = storage.get("user").userBstpSelected;
  const bstps = [
    {
      key: "/bsg/otd/order",
      value: busiType.bsgOtd,
    },
    {
      key: "/bsg/ltc/order",
      value: busiType.bsgLtc,
    },
    {
      key: "/asg/ltc/order",
      value: busiType.asgLtc,
    },
  ];
  bstps.forEach((item) => {
    if (item.key === userBstpSelected) {
      num = item.value;
      return;
    }
  });
  return num;
}

export default {
  parseTime,
  getNotEmptyObj,
  transObjKey,
  blobToObj,
  loadScript,
  getUserBstpNo,
};
