<!--
1.组件说明：选择 + 搜索组件
-->
<template>
  <div class="aptSelectSearch">
    <div class="search">
      <el-input
        v-model="mySearchValue"
        placeholder="请输入"
        @input="handleInput"
        @clear="handleClear"
        size="medium"
        clearable
        class="input-with-select"
      >
        <el-select
          slot="prepend"
          v-model="mySelectValue"
          class="select"
          size="medium"
        >
          <el-option
            :label="item.name"
            :value="item.value"
            v-for="item of selectList"
            :key="`${item.name}${item.value}`"
          ></el-option>
        </el-select>
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="search"
        ></el-button>
      </el-input>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectValue: {
      type: String | Number,
      default: undefined,
    },
    searchValue: {
      type: String,
      default: undefined,
    },
    selectList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      mySelectValue: this.selectValue,
      mySearchValue: this.searchValue,
    };
  },
  watch: {
    selectValue(newVal) {
      this.mySelectValue = newVal;
    },
    searchValue(newVal) {
      this.mySearchValue = newVal;
    },
  },
  methods: {
    //获取组件当前状态
    getData() {
      const data = {};
      for (const item of this.selectList) {
        if (this.mySelectValue === item.value) {
          data.selectValue = item.value;
          data.selectName = item.name;
          data.searchValue = this.mySearchValue;
          break;
        }
      }
      return data;
    },
    search() {
      this.$emit("search", this.getData());
    },
    handleInput() {
      this.$emit("input", this.getData());
    },
    handleClear() {
      this.$emit("clear");
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.aptSelectSearch {
  @include start;

  .input-with-select {
    .select {
      width: 120px;
    }
  }

  .search {
    position: relative;
    left: -1px;
    width: 378px;

    .el-icon-my-sousuo {
      position: absolute;
      right: 14px;
      top: 0;
      height: 100%;
      @include center;
      color: #dadada;
      font-size: 18px;
      cursor: pointer;
    }

    ::v-deep .el-input__inner {
      padding-right: 46px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
</style>
