<!------------------------------------
1.组件说明：pdf预览
2.使用了vue-pdf插件，相关参数可查看
https://www.npmjs.com/package/vue-pdf
------------------------------------->
<template>
  <div class="aptPdf">
    <pdf
      :src="mySrc"
      @num-pages="pageCount = $event"
      @page-loaded="currentPage = $event"
      :page="currentPage"
      style="width: 100%; height: auto"
    />
    <div class="btn">
      <el-button @click="pre" type="primary" plain>上一页</el-button>
      <el-button @click="next" type="primary" plain>下一页</el-button>
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf";
export default {
  components: { pdf },
  props: {
    src: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      mySrc: this.src, //资源地址
      pageCount: 0, //页面总数
      currentPage: 1, //当前页数
    };
  },
  watch: {
    src(newVal) {
      this.mySrc = newVal;
    },
  },
  methods: {
    // pdf上一页
    pre() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    // pdf下一页
    next() {
      if (this.currentPage < this.pageCount) {
        this.currentPage++;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.aptPdf {
  .btn {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex-grow: 1;
  }
}
</style>
