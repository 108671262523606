export default {
  path: "/product",
  name: "product",
  component: () =>
    import(/* webpackChunkName: "product" */ "@/views/product/index/index.vue"),
  children: [
    {
      path: "product",
      component: () =>
        import(/* webpackChunkName: "product" */ "@/views/product/product/product.vue"),
    },
  ],
};
