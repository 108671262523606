export default {
  path: "/sample",
  name: "sample",
  component: () =>
    import(/* webpackChunkName: "sample" */ "@/views/sample/index/index.vue"),
  children: [
    {
      path: "sample",
      component: () =>
        import(/* webpackChunkName: "sample" */ "@/views/sample/sample/sample.vue"),
    },
  ],
};

