<template>
  <div class="fillTestInfo_wrap">
    <p class="title">检测信息</p>
    <el-form
      :model="myData"
      :rules="fillTestInfoRules"
      ref="fillTestInfoForm"
      class="fillTestInfo_form"
      :label-position="labelPosition"
    >
      <el-form-item label="选择产品" prop="product" class="product_wrap">
        <el-select
          v-model="myData.product"
          placeholder="可通过关键词搜索"
          style="width: 100%"
          :disabled="myDisabled"
          filterable
        >
          <el-option
            :label="item.name"
            :value="item.productId"
            v-for="(item, index) of myData.productList"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" v-if="myData.requirement">
        <p>请仔细阅读该产品的检测要求：</p>
        <p
          style="line-height: 32px; font-size: 14px; color: #606266"
          v-html="myData.requirement"
        ></p>
      </el-form-item>
      <el-form-item
        :label="`${item.fieldName}`"
        :prop="`dynamicDataList[${index}].value`"
        v-for="(item, index) of myData.detectionFields"
        :key="index"
      >
        <el-input
          v-if="item.fieldType === 'text' && item.fieldName === '分子量'"
          v-model.trim="myData.dynamicDataList[index].value"
          :placeholder="item.placeholder ? item.placeholder : '请输入'"
          style="width: 50%"
          :disabled="myDisabled"
        >
          <template slot="append" v-if="item.fieldName === '分子量'"
            >Da</template
          >
        </el-input>
        <el-input
          v-if="item.fieldType === 'text' && item.fieldName === '缓冲体系'"
          v-model="myData.dynamicDataList[index].value"
          :placeholder="item.placeholder ? item.placeholder : '请输入'"
          style="width: 100%"
          :disabled="myDisabled"
        >
        </el-input>
        <el-input
          v-if="item.fieldType === 'text' && item.fieldName === '鉴定时长（H）'"
          v-model.trim="myData.dynamicDataList[index].value"
          :placeholder="item.placeholder ? item.placeholder : '请输入'"
          style="width: 50%"
          :disabled="myDisabled"
        >
          <template slot="append" v-if="item.fieldName === '鉴定时长（H）'"
            >小时</template
          >
        </el-input>
        <el-input
          v-else-if="
            item.fieldType === 'text' &&
            item.fieldName != '缓冲体系' &&
            item.fieldName != '分子量' &&
            item.fieldName != '理论序列' &&
            item.fieldName != '鉴定时长（H）'
          "
          v-model="myData.dynamicDataList[index].value"
          :placeholder="item.placeholder ? item.placeholder : '请输入'"
          style="width: 100%"
          :disabled="myDisabled"
        >
        </el-input>
        <el-input
          v-else-if="item.fieldType === 'textarea'"
          v-model="myData.dynamicDataList[index].value"
          :placeholder="item.placeholder ? item.placeholder : '请输入'"
          style="width: 100%"
          :rows="4"
          type="textarea"
          :disabled="myDisabled"
        >
        </el-input>
        <el-select
          v-else-if="item.fieldType === 'select'"
          v-model="myData.dynamicDataList[index].value"
          placeholder="请选择"
          style="width: 100%"
          :disabled="myDisabled"
        >
          <el-option
            :label="item"
            :value="item"
            v-for="(item, index) of getOptions(item.fieldItems)"
            :key="index"
          ></el-option>
        </el-select>
        <el-upload
          :disabled="myDisabled"
          v-else-if="item.fieldType === 'img'"
          list-type="picture-card"
          :on-success="
            (response, file, fileList) =>
              updateFile(file, fileList, item, index)
          "
          :on-remove="
            (file, fileList) => updateFile(file, fileList, item, index)
          "
          class="TheortSeqImg"
          style="width: 100%"
          multiple
          :limit="5"
          :headers="uploadHeaders"
          :file-list="myData.dynamicDataList[index].fileList"
          :data="uploadData"
          :action="uploadAction"
          :accept="uploadAccept"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item
        label="报告版本：(APT报告：中科新生命盖章版)"
        prop="reportVersion"
      >
        <el-select
          v-model="myData.reportVersion"
          placeholder="请先选择产品再选择版本"
          style="width: 100%"
          :disabled="myDisabled"
        >
          <el-option
            v-for="(item, index) of reportVersionList"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="报告介质" prop="reportMedium">
        <el-select
          v-model="myData.reportMedium"
          placeholder="请选择"
          style="width: 100%"
          :disabled="myDisabled"
        >
          <el-option label="电子版" value="电子版"></el-option>
          <el-option label="纸质版" value="纸质版"></el-option>
          <el-option label="两者皆需要" value="两者皆需要"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="报告语言" prop="reportLanguage">
        <el-select
          v-model="myData.reportLanguage"
          placeholder="请选择"
          style="width: 100%"
          :disabled="myDisabled"
        >
          <el-option label="中文" value="中文"></el-option>
          <el-option label="英文" value="英文"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="检测类别" prop="testType">
        <el-select
          v-model="myData.testType"
          placeholder="请选择"
          style="width: 100%"
          :disabled="myDisabled"
        >
          <el-option label="委托" value="委托"></el-option>
          <el-option label="出口" value="出口"></el-option>
          <el-option label="复核" value="复核"></el-option>
          <el-option label="其他" value="其他"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="送样方式" prop="postType">
        <el-select
          v-model="myData.postType"
          placeholder="请选择"
          style="width: 100%"
          :disabled="myDisabled"
        >
          <el-option label="物流送样" value="物流送样"></el-option>
          <el-option label="客户送样" value="客户送样"></el-option>
          <el-option
            label="委托抽样（需另外支付费用）"
            value="委托抽样（需另外支付费用）"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="余样处理" prop="handleRemain">
        <el-select
          v-model="myData.handleRemain"
          placeholder="请选择"
          style="width: 100%"
          :disabled="myDisabled"
        >
          <el-option label="不退样" value="不退样"></el-option>
          <el-option
            label="退样（请于报告书发出之日起两周内自行取回，逾期自动作废）"
            value="退样（请于报告书发出之日起两周内自行取回，逾期自动作废）"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否留样" prop="hasRemain">
        <el-select
          v-model="myData.hasRemain"
          placeholder="请选择"
          style="width: 100%"
          :disabled="myDisabled"
        >
          <el-option label="不留样" value="不留样"></el-option>
          <el-option
            label="留样（委托方提供一式三份样品，独立包装，现场封签；否则，仅对此次测试负责）"
            value="留样（委托方提供一式三份样品，独立包装，现场封签；否则，仅对此次测试负责）"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="委托备注" prop="remark" class="product_wrap">
        <el-input
          type="textarea"
          :rows="4"
          v-model="myData.remark"
          placeholder="请填写备注"
          style="width: 100%"
          :disabled="myDisabled"
          class="remark"
          maxlength="500"
          show-word-limit
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getProductDetection } from "@/api/product/index";
import { getUploadPath } from "@/api/file/index";
import utils from "@/utils/index.js";

//校验是否大于0的数字
const isGreaterThanZero = (rule, value, cb) => {
  if (utils.validate.isGreaterThanZero(value)) {
    cb();
  } else {
    cb(new Error("请填写大于0的数字"));
  }
};

export default {
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    updateDDL: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    "myData.product": function () {
      this.handleGetProductDetection();
    },
    updateDDL(newVal) {
      this.myUpdateDDL = newVal;
    },
  },
  computed: {
    myDisabled() {
      return this.disabled;
    },
  },
  data() {
    return {
      labelPosition: "top",
      myData: this.data,
      //检测信息校验规则
      fillTestInfoRules: {
        product: [
          {
            required: true,
            message: "请选择产品",
            trigger: "change",
          },
        ],
        reportVersion: [
          {
            required: true,
            message: "请选择报告版本",
            trigger: "blur",
          },
        ],
        reportMedium: [
          {
            required: true,
            message: "请选择报告介质",
            trigger: "blur",
          },
        ],
        reportLanguage: [
          {
            required: true,
            message: "请选择报告语言",
            trigger: "blur",
          },
        ],
        testType: [
          {
            required: true,
            message: "请选择检测类别",
            trigger: "blur",
          },
        ],
        postType: [
          {
            required: true,
            message: "请选择送样方式",
            trigger: "blur",
          },
        ],
        handleRemain: [
          {
            required: true,
            message: "请选择余样处理方式",
            trigger: "blur",
          },
        ],
        hasRemain: [
          {
            required: true,
            message: "请选择是否留样",
            trigger: "blur",
          },
        ],
      },
      //上传图片请求头
      uploadHeaders: {
        Authorization: this.$utils.storage.get("user").token,
      },
      //上传图片参数
      uploadData: {
        type: 0,
      },
      //上传图片路径
      uploadAction: "",
      uploadAccept: "image/jpeg,image/jpg,image/png,image/gif", //图片上传格式
      myUpdateDDL: this.updateDDL, //默认用户检测动态字段是可以更新的
      //复制初始化的检测信息校验规则
      copyFillTestInfoRules: {
        product: [
          {
            required: true,
            message: "请选择产品",
            trigger: "change",
          },
        ],
        reportVersion: [
          {
            required: true,
            message: "请选择报告版本",
            trigger: "blur",
          },
        ],
        reportMedium: [
          {
            required: true,
            message: "请选择报告介质",
            trigger: "blur",
          },
        ],
        reportLanguage: [
          {
            required: true,
            message: "请选择报告语言",
            trigger: "blur",
          },
        ],
        testType: [
          {
            required: true,
            message: "请选择检测类别",
            trigger: "blur",
          },
        ],
        postType: [
          {
            required: true,
            message: "请选择送样方式",
            trigger: "blur",
          },
        ],
        handleRemain: [
          {
            required: true,
            message: "请选择余样处理方式",
            trigger: "blur",
          },
        ],
        hasRemain: [
          {
            required: true,
            message: "请选择是否留样",
            trigger: "blur",
          },
        ],
      },
      reportVersionList: [], //通过接口请求回来的报告版本列表
    };
  },
  methods: {
    // 获取上传图片接口地址
    handleGetUploadPath() {
      getUploadPath(this.uploadData).then((data) => {
        this.uploadAction = data;
      });
    },
    // 获取数据校验规则
    getRules(item) {
      const validate = {};
      validate.required = item.isNotNull === 1 ? true : false;
      if (item.fieldType === "text") {
        //输入框
        validate.trigger = "blur";
        validate.message = `请填写${item.fieldName}`;
      } else if (item.fieldType === "select") {
        //下拉框
        validate.trigger = "blur";
        validate.message = `请选择${item.fieldName}`;
      } else if (item.fieldType === "img") {
        //图片上传
        validate.trigger = "change";
        validate.message = `请上传${item.fieldName}`;
      } else if (item.fieldType === "textarea") {
        //文本域
        validate.trigger = "blur";
        validate.message = `请选择${item.fieldName}`;
      }
      //如果是数字类型的输入，添加数字类型的校验规则
      const validate2 = {};
      const mathProps = ["分子量"];
      if (mathProps.includes(item.fieldName)) {
        validate2.trigger = validate.trigger;
        validate2.required = validate.required;
        validate2.validator = isGreaterThanZero;
      }

      //如果是数字类型的输入，添加数字类型的校验规则
      const validate3 = {};
      if (item.fieldType === "text" && item.regularExpression) {
        validate3.trigger = validate.trigger;
        validate3.pattern = item.regularExpression;
        validate3.message = item.errMsg;
      }

      var validateList = [validate];
      if (validate2.trigger) {
        validateList.push(validate2);
      }
      if (validate3.trigger) {
        validateList.push(validate3);
      }
      return validateList;
    },
    // 获取选项列表
    getOptions(item) {
      if (!item) return;
      return item.split("|");
    },
    // 转换上传的图片数据
    transFileList(value) {
      const arr2 = [];
      if (value) {
        const arr = value.split(",");
        arr.forEach((item) => {
          arr2.push({
            url: item,
          });
        });
      }
      return arr2;
    },
    // 更新图片数据
    updateFile(file, fileList, item, index) {
      if (item.fieldType === "img") {
        const imgUrls = [];
        const files = [];
        fileList.forEach((item) => {
          //fileList里包含了返显数据，需要判断
          if (item.response && item.response.msg) {
            imgUrls.push(item.response.msg);
            files.push({
              url: item.response.msg,
            });
          } else if (item.url) {
            imgUrls.push(item.url);
            files.push({
              url: item.url,
            });
          }
        });
        this.myData.dynamicDataList[index].value = imgUrls.join(",");
        this.myData.dynamicDataList[index].fileList = files;
      }
    },
    // 获取报告版本列表
    getReportVersionList(version) {
      const list = version.split("|");
      const newList = [];
      list.forEach((item) => {
        newList.push({
          label: item,
          value: item,
        });
      });
      if (!version.includes(this.myData.reportVersion)) {
        this.myData.reportVersion = "";
      }
      this.reportVersionList.splice(
        0,
        this.reportVersionList.length,
        ...newList
      );
    },
    // 获取产品动态检测信息字段
    handleGetProductDetection() {
      if (this.myData.product) {
        const params = {
          productId: this.myData.product,
        };
        getProductDetection(params).then((res) => {
          //产品动态检测信息字段
          this.myData.detectionFields.splice(
            0,
            this.myData.detectionFields.length,
            ...res.detectionFields
          );
          const copy = {
            ...this.copyFillTestInfoRules,
          };
          if (this.myUpdateDDL) {
            // 可以更新动态字段

            //检测要求
            this.myData.requirement = res.requirement;

            //更新用户填写动态检测信息字段
            const validate = [];

            //保留上一次的产品填写数据
            const preDynamicDataList = JSON.parse(
              JSON.stringify(this.myData.dynamicDataList)
            );
            //本次数据
            const dynamicDataList = [];

            //用户填写(产品动态检测信息字段)
            this.myData.detectionFields.forEach((item, index) => {
              //切换产品时，如果本次动态字段跟上一次有相同的，则展示上一次的值。
              const commonField = preDynamicDataList.find(
                (item2) => item2.fieldCode === item.fieldCode
              );
              const obj = {
                fieldId: item.id,
                name: item.fieldName,
                value: commonField?.fieldCode ? commonField.value : "",
                fileList: commonField?.fieldCode ? commonField.fileList : [],
                fieldCode: item.fieldCode,
                fieldItems: item.fieldItems,
                fieldType: item.fieldType,
              };
              //如果切换前和切换后，都是选择框，需要判断选择项目的值是否存在，存在反显，反之则不反显。
              if (item.fieldType === "select") {
                if (item.fieldItems.includes(commonField?.value)) {
                  obj.value = commonField.value;
                } else {
                  obj.value = "";
                }
              }
              dynamicDataList.push(obj);
              copy[`dynamicDataList[${index}].value`] = this.getRules(item); //添加一条动态检测数据校验规则
            });
            // 清空动态检测数据并赋予最新值
            this.myData.dynamicDataList.splice(
              0,
              this.myData.dynamicDataList.length,
              ...dynamicDataList
            );
          } else {
            //不可以更新动态字段
            const dynamicDataList = [];
            const preDynamicDataList = [];
            this.myData.dynamicDataList.forEach((item) => {
              preDynamicDataList.push(item.fieldId);
            });
            this.myData.detectionFields.forEach((item, index) => {
              copy[`dynamicDataList[${index}].value`] = this.getRules(item); //添加一条动态检测数据校验规则
              //更新动态检测字段列表顺序，使其与默认的detectionFields一致。
              if (preDynamicDataList.includes(item.id)) {
                this.myData.dynamicDataList.forEach((item2) => {
                  const obj = {
                    ...item,
                    ...item2,
                  };
                  if (item.id === item2.fieldId) {
                    if (item.fieldType === "img") {
                      obj.fileList = this.transFileList(item2.value);
                    }
                    dynamicDataList.push(obj);
                  }
                });
              } else {
                dynamicDataList.push({
                  fieldId: item.id,
                  name: item.fieldName,
                  value: "",
                  fileList: [],
                  fieldCode: item.fieldCode,
                  fieldItems: item.fieldItems,
                  fieldType: item.fieldType,
                });
              }
            });

            this.myData.dynamicDataList.splice(
              0,
              this.myData.dynamicDataList.length,
              ...dynamicDataList
            ); //更新动态检测字段列表顺序，使其与默认的detectionFields一致。

            this.myUpdateDDL = true; //重置为可更新状态
          }
          this.fillTestInfoRules = Object.assign(this.fillTestInfoRules, copy); //填写检测信息校验规则
          this.getReportVersionList(res.reportVersion);
          this.$forceUpdate();
        });
      }
    },
    async validate() {
      let result = undefined;
      await this.$refs.fillTestInfoForm.validate((valid) => {
        result = valid;
      });
      return result;
    },
    async validateField(params) {
      //params: array | string，需要校验的字段,不填则全部校验
      let result = undefined;
      await this.$refs.fillTestInfoForm.validateField(params, (errMsg) => {
        result = errMsg;
      });
      return result;
    },
    resetFields() {
      this.$refs.fillTestInfoForm.resetFields();
      this.myData.detectionFields = [];
      this.myData.dynamicDataList = [];
      this.myData.preDynamicDataList = [];
    },
  },
  created() {
    this.handleGetUploadPath();
    this.handleGetProductDetection();
  },
};
</script>

<style lang="scss" scoped>
.fillTestInfo_wrap {
  .title {
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: 600;
  }
}

/*样式穿透*/
.fillTestInfo ::v-deep .fillTestInfo_form {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .add {
    font-size: 28px;
    color: #8c939d;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
  }

  .product_wrap {
    position: relative;
    width: 100%;

    .product_introduce {
      display: flex;
      flex-grow: 1;
    }
  }

  .TheortSeqImg {
    .el-upload-list--picture-card,
    .el-upload-list__item,
    .el-upload--picture-card {
      width: 94px;
      height: 94px;
    }

    .el-upload--picture-card {
      line-height: 94px;
    }
  }

  .remark {
    .el-textarea__inner {
      height: 72px;
    }
  }

  .submit {
    display: flex;
    width: 100%;
    margin-top: 20px;
  }

  .el-input__inner {
    height: 36px;
    line-height: 36px;
  }
}
</style>
