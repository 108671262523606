import axios from "axios";
import storage from "./storage";
import router from "../router";
import { Message, Loading } from "element-ui";
import { saveAs } from "file-saver";
import map from "./map";

const service = axios.create({
  // 设置超时时间
  timeout: 8000,
  baseURL: "",
  method: "post",
  loading: true,
});

let loading = null; //请求响应动画

/**
 * 请求前拦截
 * 用于处理需要在请求前的操作
 */
service.interceptors.request.use(
  (config) => {
    // 在请求先展示加载框
    if (config.loading) {
      loading = Loading.service({
        text: "正在加载中......",
      });
    }

    // 用户认证
    const user = storage.get("user");
    const token = user && user.token ? user.token : undefined;
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * 请求响应拦截
 * 用于处理需要在请求返回后的操作
 */
service.interceptors.response.use(
  async (response) => {
    // 请求响应后关闭加载框
    if (loading) {
      loading.close();
    }
    const resData = response.data;
    const { config, status, headers } = response; //`config` 是 `axios` 请求的配置信息，`status` 来自服务器响应的 HTTP 状态码
    if (status >= 200 && status < 400 && config.responseType === "blob") {
      //转化成json对象，先判断响应是否正确
      const jsonData = await map.blobToObj(resData);
      if (jsonData && jsonData.code === -1) {
        //网络请求参数有误
        Message({
          type: "error",
          message: jsonData.message,
        });
        return Promise.reject(jsonData && jsonData.data);
      } else {
        //服务端响应成功并且数据类型设定为blob，用来处理二进制文件流
        const resBlobData = {};
        resBlobData.data = resData;
        const contentDisposition = headers["content-disposition"].split(";");
        let fileName = contentDisposition.find((item) =>
          item.includes("filename")
        );
        resBlobData.fileName = fileName ? fileName.split("=")[1] : "未命名";
        resBlobData.fileName = "未命名";
        return Promise.resolve(resBlobData);
      }
    } else if (resData.code === 200) {
      // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
      // 否则的话抛出错误
      return Promise.resolve(resData.data);
    } else if (resData.version) {
      // 如果是版本检验接口则正常返回
      return Promise.resolve(resData);
    } else if (resData.code === -1) {
      //网络请求参数有误
      Message({
        type: "error",
        message: resData.message,
      });
      return Promise.reject(resData && resData.data);
    } else {
      //其它类型错误
      return Promise.reject(resData && resData.data);
    }
  },
  (error) => {
    // 服务器返回 http状态码不是2 开头的情况，会进入这个回调
    // 请求响应后关闭加载框
    if (loading) {
      loading.close();
    }
    if (error.response) {
      const status = error.response.status;
      console.log(status);
      // 服务端异常
      if (status === 401) {
        // 未登录
        // 跳转登录页
        router.replace({
          path: "/home",
          query: {
            redirect: router.currentRoute.fullPath,
          },
        });
      } else if (status === 404) {
        // 网络请求不存在
        Message({
          type: "error",
          message: "网络请求不存在",
        });
      } else if (status === 500) {
        // 服务端异常
        Message({
          type: "error",
          message: "服务端异常",
        });
      } else {
        // 服务端未知异常
        Message({
          type: "error",
          message: "服务端未知错误",
        });
      }
      return Promise.reject(error.response);
    } else {
      // 网络错误
      if (error.message && error.message.includes("timeout")) {
        // 请求超时状态
        Message({
          message: "请求超时，请检查网络是否连接正常",
          type: "error",
        });
      } else {
        // 断网状态
        Message({
          message: "请求失败，请检查网络是否已连接",
          type: "error",
        });
      }
    }
    return Promise.reject(error);
  }
);

export default service;
