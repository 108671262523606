/**
 * @desc BSG-OTD接口
 */

import request from "@/utils/request";

// 获取订单的审核详情
export function approveDetail(id) {
  return request({
    url: `/apt-css/order/bsg/otd/get/approveDetail/${id}`,
    method: "get",
  });
}

// 获取订单的加急详情
export function urgentDetail(id) {
  return request({
    url: `/apt-css/order/bsg/otd/get/urgentDetail/${id}`,
    method: "get",
  });
}

// 获取订单的售后详情
export function aftersaleDetail(id) {
  return request({
    url: `/apt-css/order/bsg/otd/get/aftersale/${id}`,
    method: "get",
  });
}

// 获取报告
export function getReport(params) {
  return request({
    url: `/apt-css/order/bsg/otd/legacy/reportChecks`,
    method: "get",
    params,
  });
}

// 获取委托协议&报告 寄送信息
export function getContractInfo(params) {
  return request({
    url: `/apt-css/order/bsg/otd/getContractInfo`,
    method: "get",
    params,
  });
}

// 内部下单查询项目信息列表
export function getInnerProject(params) {
  return request({
    url: `/apt-css/order/bsg/otd/getInnerProject`,
    method: "get",
    params: params.params,
    loading: params.loading,
  });
}

//获取公司员工信息
export function listSysUser(params) {
  return request({
    url: "/apt-user/user/listSysUser",
    method: "get",
    params: params.params,
    loading: params.loading,
  });
}

//申请打印报告
export function applyPrint(data) {
  return request({
    url: `/apt-css/order/bsg/otd/applyPrintReport`,
    method: "post",
    data,
  });
}

//批量下载
export function batchDownload(params) {
  return request({
    url: `/apt-css/order/bsg/otd/batchDownload`,
    method: "get",
    params,
    responseType: "blob",
  });
}

//提交订单评分
export function rateOrder(data) {
  return request({
    url: `/apt-css/order/bsg/otd/rateOrder`,
    method: "post",
    data,
  });
}

//查看订单评分
export function queryRate(params) {
  return request({
    url: "/apt-css/order/bsg/otd/queryRate",
    method: "get",
    params,
  });
}
