<!-- 1.下单组件。包括：下单、修改、查看详情 -->

<template>
  <div class="aptCreateOrder">
    <el-drawer
      title="提交订单"
      :visible.sync="myOpen"
      direction="rtl"
      :wrapperClosable="false"
      size="65%"
      class="drawer"
      :before-close="handleClose"
      @close="close"
      append-to-body
    >
      <create
        v-if="myOpen"
        ref="create"
        @cancel="close"
        v-bind="$attrs"
        @success="success"
      />
    </el-drawer>
  </div>
</template>

<script>
import create from "@/views/components/order/create/create.vue";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "新建订单",
    },
  },
  components: {
    create,
  },
  watch: {
    value: {
      handler(newVal) {
        this.myOpen = newVal;
      },
    },
  },
  data() {
    return {
      myOpen: this.value, //是否展示弹窗
    };
  },
  computed: {
    myTitle() {
      return this.title;
    }, //弹窗标题
  },
  methods: {
    handleClose(done) {
      this.$refs.create.isSaveData(done);
    },
    close() {
      this.myOpen = false;
      this.$emit("input", this.myOpen);
    },
    success() {
      this.close();
      this.$emit("success");
    },
  },
};
</script>

<style lang="scss" scoped>
.aptCreateOrder {
  position: absolute;
  z-index: -10;
}

.drawer {
  ::v-deep .el-drawer__header > :first-child {
    font-family: "微软雅黑", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #333333;
    font-size: 18px;
  }
}
</style>
