/*
 **全局组件自动注册
 */
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = []; //存放路由组件
const requireComponent = require.context(
  // 其组件目录的相对路径
  ".",
  // 是否查询其子目录
  false,
  // 匹配基础组件文件名的正则表达式
  /[A-Za-z]\w+\.js$/
);
requireComponent.keys().forEach((fileName) => {
  //入口文件不进行配置
  if (fileName.includes("./index")) return false;
  // 获取组件配置
  const componentConfig = requireComponent(fileName);
  routes.push(componentConfig.default || componentConfig);
});

const router = new VueRouter({
  routes,
});

//解决报错：跳转路由跟当前路由一样
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
