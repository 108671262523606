export default {
  path: "/demo",
  name: "demo",
  component: () =>
    import(/* webpackChunkName: "demo" */ "@/views/demo/index/index.vue"),
  children: [
    {
      path: "demo",
      component: () =>
        import(/* webpackChunkName: "demo" */ "@/views/demo/demo/demo.vue"),
    },
  ],
};