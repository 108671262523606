export default {
  path: "/switch",
  name: "switch",
  component: () =>
    import(/* webpackChunkName: "switch" */ "@/views/switch/index/index.vue"),
  children: [
    {
      path: "switch",
      component: () =>
        import(/* webpackChunkName: "switch" */ "@/views/switch/switch/switch.vue"),
    },
  ],
};
