<!-- 1.组件说明：批量下单组件 -->
<template>
  <div class="aptBatchCreateOrder">
    <el-drawer
      title="批量新建"
      :visible.sync="myOpen"
      direction="rtl"
			:wrapperClosable="false"
      size="75%"
      class="drawer"
      @close="close"
      append-to-body
    >
      <batch-create v-if="myOpen"  @cancel="close" @back="close"/>
    </el-drawer>
  </div>
</template>

<script>
import batchCreate from "@/views/components/order/batchCreate/batchCreate.vue";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    batchCreate,
  },
  watch: {
    value: {
      handler(newVal) {
        this.myOpen = newVal;
      },
    },
  },
  data() {
    return {
      myOpen: this.value, //是否展示弹窗
    };
  },
  methods: {
    close() {
      this.myOpen = false;
      this.$emit("input", this.myOpen);
    }
  },
};
</script>

<style lang="scss" scoped>
.aptBatchCreateOrder {
  position: absolute;
  z-index: -10;
}

.drawer {
  ::v-deep .el-drawer__header > :first-child {
    font-family: "微软雅黑", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #333333;
    font-size: 18px;
  }
}
</style>
