<template>
  <div class="bcTable">
    <el-form :model="formData" ref="elForm" :rules="formRules">
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{
          'background-color': '#ffffff',
          color: '#000000',
          'font-weight': 400,
        }"
        class="elTable"
        ref="elTable"
      >
        <el-table-column label="产品类型" width="240" class="product_wrap">
          <template slot="header">
            <span class="header"><span class="required">*</span>产品：</span>
          </template>
          <template slot-scope="scope">
            <el-form-item :prop="`data[${scope.$index}].product`">
              <el-select
                v-model="scope.row.fillTestInfoForm.product"
                @change="handleGetProductDetection(scope.row)"
                placeholder="请选择"
              >
                <el-option
                  :label="item.name"
                  :value="item.productId"
                  v-for="item of productList"
                  :key="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="demoOrigin" label="样品来源" width="140">
          <template slot="header">
            <span class="header"
              ><span class="required">*</span>样品来源：</span
            >
          </template>
          <template slot-scope="scope">
            <el-form-item :prop="`data[${scope.$index}].demoOrigin`">
              <el-select
                v-model="scope.row.fillDemoInfoForm.demoOrigin"
                placeholder="请选择"
              >
                <el-option
                  :label="item.name"
                  :value="item.value"
                  v-for="item of demo.demoOrigin"
                  :key="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="demoName" label="样品名称" width="140">
          <template slot="header">
            <span class="header"
              ><span class="required">*</span>样品名称：</span
            >
          </template>
          <template slot-scope="scope">
            <el-form-item :prop="`data[${scope.$index}].demoName`">
              <el-input
                v-model="scope.row.fillDemoInfoForm.demoName"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="demoNo" label="样品批号" width="140">
          <template slot="header">
            <span class="header"
              ><span class="required">*</span>样品批号：</span
            >
          </template>
          <template slot-scope="scope">
            <el-form-item :prop="`data[${scope.$index}].demoNo`">
              <el-input v-model="scope.row.fillDemoInfoForm.demoNo"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="demoAmount" label="样品数量" width="110">
          <template slot="header">
            <span class="header"
              ><span class="required">*</span>样品数量：</span
            >
          </template>
          <template slot-scope="scope">
            <el-form-item :prop="`data[${scope.$index}].demoAmount`">
              <el-input
                v-model="scope.row.fillDemoInfoForm.demoAmount"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="purity" label="样品纯度" width="110">
          <template slot="header">
            <span class="header"
              ><span class="required">*</span>样品纯度：</span
            >
          </template>
          <template slot-scope="scope">
            <el-form-item :prop="`data[${scope.$index}].purity`">
              <el-input v-model="scope.row.fillDemoInfoForm.purity"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="demoShape" label="样品性状" width="140">
          <template slot="header">
            <span class="header"
              ><span class="required">*</span>样品性状：</span
            >
          </template>
          <template slot-scope="scope">
            <el-form-item :prop="`data[${scope.$index}].demoShape`">
              <el-input
                v-model="scope.row.fillDemoInfoForm.demoShape"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="demoStandard" label="样品规格" width="140">
          <template slot="header">
            <span class="header"
              ><span class="required">*</span>样品规格：</span
            >
          </template>
          <template slot-scope="scope">
            <el-form-item :prop="`data[${scope.$index}].demoStandard`">
              <el-input
                v-model="scope.row.fillDemoInfoForm.demoStandard"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="storeCondition" label="贮存条件" width="140">
          <template slot="header">
            <span class="header"
              ><span class="required">*</span>贮存条件：</span
            >
          </template>
          <template slot-scope="scope">
            <el-form-item :prop="`data[${scope.$index}].storeCondition`">
              <el-input
                v-model="scope.row.fillDemoInfoForm.storeCondition"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="280">
          <template slot-scope="scope">
            <div class="operation_row">
              <span class="item" @click="handleDetail(scope.row)"
                >录入明细</span
              >
              <span class="item" @click="copyOrder(scope.row)">复制新增</span>
              <span class="item red" @click="deleteBsgOtdOrder(scope.row)">删除</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <detail
        v-if="dialogOperation"
        :data="row"
        :open="dialogOperation"
        :productList="productList"
        @close="(val) => (dialogOperation = val)"
      />
    </el-form>
  </div>
</template>

<script>
import { listProduct, getProductDetection } from "@/api/product/index";
import detail from "./detail.vue";
import { order } from "@/utils/dict";
export default {
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    detail,
  },
  data() {
    return {
      tableData: this.data, //表格数据
      // expands: [], //扩展行
      productList: [], //产品列表
      dialogOperation: false, //录入明细弹窗是否显示
      row: null, //当前选中的某项数据
      demo: order.demo, //样本字典
    };
  },
  computed: {
    formData() {
      const data = this.tableData.map((item) => {
        const obj = {
          product: item.fillTestInfoForm.product, //检测产品
          demoOrigin: item.fillDemoInfoForm.demoOrigin, //样品来源
          demoName: item.fillDemoInfoForm.demoName, //产品名称
          demoNo: item.fillDemoInfoForm.demoNo, //样品编号
          demoAmount: item.fillDemoInfoForm.demoAmount, //样品数量
          purity: item.fillDemoInfoForm.purity, //纯度
          demoShape: item.fillDemoInfoForm.demoShape, //样品性状
          demoStandard: item.fillDemoInfoForm.demoStandard, //样品规格
          storeCondition: item.fillDemoInfoForm.storeCondition, //贮存条件
        };
        return obj;
      });
      return {
        data: data,
      };
    },
    formRules() {
      let rules = {};
      this.tableData.map((item, index) => {
        const obj = {
          [`data[${index}].product`]: item.fillTestInfoRules.product, //检测产品
          [`data[${index}].demoOrigin`]: item.fillDemoInfoRules.demoOrigin, //样品来源
          [`data[${index}].demoName`]: item.fillDemoInfoRules.demoName, //产品名称
          [`data[${index}].demoNo`]: item.fillDemoInfoRules.demoNo, //样品编号
          [`data[${index}].demoAmount`]: item.fillDemoInfoRules.demoAmount, //样品数量
          [`data[${index}].purity`]: item.fillDemoInfoRules.purity, //纯度
          [`data[${index}].demoShape`]: item.fillDemoInfoRules.demoShape, //样品性状
          [`data[${index}].demoStandard`]: item.fillDemoInfoRules.demoStandard, //样品规格
          [`data[${index}].storeCondition`]:
            item.fillDemoInfoRules.storeCondition, //贮存条件
        };
        rules = { ...rules, ...obj };
      });
      return rules;
    },
  },
  methods: {
    // 获取产品列表
    handleListProduct() {
      listProduct().then((res) => {
        this.productList.splice(0, this.productList.length, ...res);
      });
    },
    // 录入明细
    handleDetail(row) {
      this.dialogOperation = true;
      this.row = row;
    },
    // 复制新增（复制上一行订单）
    copyOrder(row) {
      if (this.tableData.length >= 10) {
        this.$message({
          type: "warning",
          message: "批量新建最多支持10条数据",
        });
        return;
      }
      //复制
      const newRow = JSON.parse(JSON.stringify(row));
      this.tableData.push({
        ...newRow,
        index: this.tableData.length,
      });
      console.log(this.tableData);
    },
    // 删除订单
    deleteBsgOtdOrder(row) {
      //删除
      this.tableData.splice(row.index, 1);
      //重新计算index
      this.tableData.forEach((item, index) => {
        item.index = index;
      });
    },
    // 获取数据校验规则
    getRules(item) {
      const validate = {};
      validate.required = item.required === "1" ? true : false;
      if (item.fieldType === "text") {
        //输入框
        validate.trigger = "blur";
        validate.message = `请填写${item.fieldName}`;
      } else if (item.fieldType === "select") {
        //下拉框
        validate.trigger = "blur";
        validate.message = `请选择${item.fieldName}`;
      } else if (item.fieldType === "img") {
        //图片上传
        validate.trigger = "change";
        validate.message = `请上传${item.fieldName}`;
      }
      return validate;
    },
    // 获取产品动态检测信息字段
    handleGetProductDetection(row) {
      if (row.fillTestInfoForm && row.fillTestInfoForm.product) {
        const product = row.fillTestInfoForm.product;
        const params = {
          productId: product,
        };
        getProductDetection(params).then((res) => {
          //产品动态检测信息字段
          row.fillTestInfoForm.detectionFields.splice(
            0,
            row.fillTestInfoForm.detectionFields.length,
            ...res.detectionFields
          );

          //产品介绍
          row.fillTestInfoForm.requirement = res.requirement;
          //更新动态检测信息字段
          const validate = [];
          row.fillTestInfoForm.dynamicDataList.splice(
            0,
            row.fillTestInfoForm.dynamicDataList.length
          ); //清空动态检测数据
          const copy = {
            ...this.fillTestInfoRules,
          };
          row.fillTestInfoForm.detectionFields.forEach((item, index) => {
            //用户填写(产品动态检测信息字段)
            row.fillTestInfoForm.dynamicDataList.push({
              fieldId: item.id,
              name: item.fieldName,
              value: "",
            });
            copy[item.fieldCode] = this.getRules(item); //添加一条动态检测数据校验规则
          });
          row.fillTestInfoRules = Object.assign(row.fillTestInfoRules, copy); //填写检测信息校验规则
          // this.updateExpands(row);
        });
      }
    },
    // 批量校验
    validate() {
      this.$refs.elForm.validate((valid) => {
        this.$emit("validate", valid);
      });
    },
  },
  created() {
    this.handleListProduct();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/elementuiReset.scss";
.bcTable {
  flex-grow: 1;
  .elTable {
    .demoInfo {
      color: $theme_color;
      cursor: pointer;
      align-items: center;
    }
    ::v-deep .operation_row {
      color: $theme_color;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .item {
        margin-right: 20px;
        cursor: pointer;
      }
      .red {
        color: #ff3300;
      }
    }
    .header {
      .required {
        color: red;
        padding-right: 5px;
      }
    }
    & ::v-deep .el-form-item {
      margin-top: 22px;
    }
    & ::v-deep .el-input__inner {
      height: 36px;
      line-height: 36px;
    }
  }
}
</style>
