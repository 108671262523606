<template>
  <div class="detail">
    <el-drawer
      title="详情编辑"
      :visible.sync="dialogOperation"
      direction="rtl"
      size="60%"
      class="dialogOperation"
      @close="close"
      append-to-body
    >
      <div class="create">
        <div class="fillTestInfo_wrap">
          <div class="header">
            <span class="title">检测信息</span>
            <span class="lineX"></span>
          </div>
          <div class="fillTestInfo">
            <el-form
              :model="myData.fillTestInfoForm"
              :rules="myData.fillTestInfoRules"
              ref="fillTestInfoForm"
              label-width="200px"
              class="fillTestInfo_form"
              v-if="myData && myData.fillTestInfoForm"
            >
              <el-form-item label="选择产品:" prop="product">
                <template slot="header">
                  <span class="header">选择产品:</span>
                </template>
                <el-select
                  v-model="myData.fillTestInfoForm.product"
                  placeholder="搜索并请选择"
                  style="width: 240px"
                  filterable
                >
                  <el-option
                    :label="item.name"
                    :value="item.productId"
                    v-for="(item, index) of myProductList"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item
                :label="`${item.fieldName}:`"
                :prop="`dynamicDataList[${index}].value`"
                v-for="(item, index) of myData.fillTestInfoForm.detectionFields"
                :key="index"
              >
                <el-input
                  v-if="item.fieldType === 'text'"
                  v-model="myData.fillTestInfoForm.dynamicDataList[index].value"
                  :placeholder="item.placeholder"
                  style="width: 240px"
                >
                  <span v-if="item.unit" slot="append">{{ item.unit }}</span>
                </el-input>
                <el-select
                  v-else-if="item.fieldType === 'select'"
                  v-model="myData.fillTestInfoForm.dynamicDataList[index].value"
                  placeholder="请选择"
                  style="width: 240px"
                >
                  <el-option
                    :label="item"
                    :value="item"
                    v-for="(item, index) of getOptions(item.fieldItems)"
                    :key="index"
                  ></el-option>
                </el-select>
                <el-upload
                  v-else-if="item.fieldType === 'img'"
                  list-type="picture-card"
                  :on-success="
                    (response, file, fileList) =>
                      updateFile(file, fileList, item, index)
                  "
                  :on-remove="
                    (file, fileList) => updateFile(file, fileList, item, index)
                  "
                  class="TheortSeqImg"
                  multiple
                  :limit="5"
                  :headers="uploadHeaders"
                  :file-list="fileLists[index]"
                  :data="uploadData"
                  :action="uploadAction"
                >
                  <i class="el-icon-plus add"></i>
                </el-upload>
              </el-form-item>

              <el-form-item label="报告版本：(APT报告：中科新生命盖章版)" prop="reportVersion">
                <el-select
                  v-model="myData.fillTestInfoForm.reportVersion"
                  placeholder="请选择"
                  style="width: 240px"
                >
                  <el-option
                    label="CMA报告+APT报告"
                    value="CMA报告+APT报告"
                    style="width: 320px"
                  ></el-option>
                  <el-option
                    label="中科新生命版 (APT版)"
                    value="中科新生命版 (APT版)"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="报告介质:" prop="reportMedium">
                <el-select
                  v-model="myData.fillTestInfoForm.reportMedium"
                  placeholder="请选择"
                  style="width: 240px"
                >
                  <el-option label="电子版" value="电子版"></el-option>
                  <el-option label="纸质版" value="纸质版"></el-option>
                  <el-option label="两者皆需要" value="两者皆需要"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="报告语言:" prop="reportLanguage">
                <el-select
                  v-model="myData.fillTestInfoForm.reportLanguage"
                  placeholder="请选择"
                  style="width: 240px"
                >
                  <el-option label="中文" value="中文"></el-option>
                  <el-option label="英文" value="英文"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="检测类别:" prop="testType">
                <el-select
                  v-model="myData.fillTestInfoForm.testType"
                  placeholder="请选择"
                  style="width: 240px"
                >
                  <el-option label="委托" value="委托"></el-option>
                  <el-option label="出口" value="出口"></el-option>
                  <el-option label="复核" value="复核"></el-option>
                  <el-option label="其他" value="其他"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="送样方式:" prop="postType">
                <el-select
                  v-model="myData.fillTestInfoForm.postType"
                  placeholder="请选择"
                  style="width: 240px"
                >
                  <el-option label="物流送样" value="物流送样"></el-option>
                  <el-option label="客户送样" value="客户送样"></el-option>
                  <el-option
                    label="委托抽样（需另外支付费用）"
                    value="委托抽样（需另外支付费用）"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="余样处理:" prop="handleRemain">
                <el-select
                  v-model="myData.fillTestInfoForm.handleRemain"
                  placeholder="请选择"
                  style="width: 240px"
                >
                  <el-option label="不退样" value="不退样"></el-option>
                  <el-option
                    label="退样（请于报告书发出之日起两周内自行取回，逾期自动作废）"
                    value="退样（请于报告书发出之日起两周内自行取回，逾期自动作废）"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="是否留样:" prop="hasRemain">
                <el-select
                  v-model="myData.fillTestInfoForm.hasRemain"
                  placeholder="请选择"
                  style="width: 240px"
                >
                  <el-option label="不留样" value="不留样"></el-option>
                  <el-option
                    label="留样（委托方提供一式三份样品，独立包装，现场封签；否则，仅对此次测试负责）"
                    value="留样（委托方提供一式三份样品，独立包装，现场封签；否则，仅对此次测试负责）"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="委托备注:"
                prop="remark"
                class="product_wrap"
              >
                <el-input
                  type="textarea"
                  v-model="myData.fillTestInfoForm.remark"
                  placeholder="最多不能超过500个汉字"
                  maxlength="500"
                  style="width: 500px"
                  class="remark"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item class="submit">
                <el-button
                  type="primary"
                  @click="submitForm('myData.fillTestInfoForm')"
                  >保存</el-button
                >
                <el-button type="primary" plain @click="saveForm"
                  >保存至草稿</el-button
                >
              </el-form-item> -->
            </el-form>
          </div>
        </div>
        <div class="fillDemoInfo_wrap">
          <div class="header">
            <span class="title">样品信息</span>
            <span class="lineX"></span>
          </div>
          <div class="fillDemoInfo">
            <el-form
              :model="myData.fillDemoInfoForm"
              :rules="myData.fillDemoInfoRules"
              ref="fillDemoInfoForm"
              label-width="200px"
              class="fillDemoInfo_form"
              v-if="myData && myData.fillDemoInfoForm"
            >
              <el-form-item label="样品类型:" prop="demoType">
                <el-select
                  v-model="myData.fillDemoInfoForm.demoType"
                  placeholder="请选择"
                  style="width: 240px"
                >
                  <el-option label="小肽类" value="小肽类"></el-option>
                  <el-option label="重组蛋白" value="重组蛋白"></el-option>
                  <el-option label="单抗类" value="单抗类"></el-option>
                  <el-option label="其他类" value="其他类"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="样品来源:" prop="demoOrigin">
                <el-select
                  v-model="myData.fillDemoInfoForm.demoOrigin"
                  placeholder="请选择"
                  style="width: 240px"
                >
                  <el-option label="生产" value="生产"></el-option>
                  <el-option
                    label="购买（请提供原包装信息）"
                    value="购买（请提供原包装信息）"
                  ></el-option>
                  <el-option
                    label="其他（备注里请明确）"
                    value="其他（备注里请明确）"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="样品名称:" prop="demoName">
                <el-input
                  v-model="myData.fillDemoInfoForm.demoName"
                  placeholder="请填写"
                  style="width: 240px"
                ></el-input>
              </el-form-item>
              <el-form-item label="样品批号:" prop="demoNo">
                <el-input
                  v-model="myData.fillDemoInfoForm.demoNo"
                  placeholder="请填写"
                  style="width: 240px"
                ></el-input>
              </el-form-item>
              <el-form-item label="样品数量:" prop="demoAmount">
                <el-input
                  v-model="myData.fillDemoInfoForm.demoAmount"
                  placeholder="请填写"
                  style="width: 240px"
                ></el-input>
              </el-form-item>
              <el-form-item label="样品性状:" prop="demoShape">
                <el-select
                  v-model="myData.fillDemoInfoForm.demoShape"
                  placeholder="请选择"
                  style="width: 240px"
                >
                  <el-option
                    label="无色澄清液体"
                    value="无色澄清液体"
                  ></el-option>
                  <el-option
                    label="淡黄色澄清液体"
                    value="淡黄色澄清液体"
                  ></el-option>
                  <el-option
                    label="白色固体粉末"
                    value="白色固体粉末"
                  ></el-option>
                  <el-option
                    label="类白色固体粉末"
                    value="类白色固体粉末"
                  ></el-option>
                  <el-option label="其他" value="其他"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="样品纯度:" prop="purity">
                <el-input
                  v-model="myData.fillDemoInfoForm.purity"
                  placeholder="请填写"
                  style="width: 240px"
                ></el-input>
              </el-form-item>
              <el-form-item label="截止有效期:" prop="endDate">
                <el-date-picker
                  v-model="myData.fillDemoInfoForm.endDate"
                  type="date"
                  placeholder="选择日期"
                  style="width: 240px"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="样品规格:" prop="demoStandard">
                <el-select
                  v-model="myData.fillDemoInfoForm.demoStandard"
                  placeholder="请选择"
                  style="width: 240px"
                >
                  <el-option label="原料药" value="原料药"></el-option>
                  <el-option label="化合物" value="化合物"></el-option>
                  <el-option label="成品药" value="成品药"></el-option>
                  <el-option label="中间体" value="中间体"></el-option>
                  <el-option label="其他" value="其他"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="贮存条件:" prop="storeCondition">
                <el-select
                  v-model="myData.fillDemoInfoForm.storeCondition"
                  placeholder="请选择"
                  style="width: 240px"
                >
                  <el-option label="室温" value="室温"></el-option>
                  <el-option label="2-8℃" value="2-8℃"></el-option>
                  <el-option label="-15至-25℃" value="-15至-25℃"></el-option>
                  <el-option label="-65至-85℃" value="-65至-85℃"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="样品包装:" prop="demoPack">
                <el-select
                  v-model="myData.fillDemoInfoForm.demoPack"
                  placeholder="请选择"
                  style="width: 240px"
                >
                  <el-option label="离心管" value="离心管"></el-option>
                  <el-option label="玻璃瓶" value="玻璃瓶"></el-option>
                  <el-option label="塑料容器" value="塑料容器"></el-option>
                  <el-option label="其他" value="其他"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="样品状态:" prop="demoState">
                <el-select
                  v-model="myData.fillDemoInfoForm.demoState"
                  placeholder="请选择"
                  style="width: 240px"
                >
                  <el-option label="固体" value="固体"></el-option>
                  <el-option label="液体" value="液体"></el-option>
                  <el-option label="凝胶" value="凝胶"></el-option>
                  <el-option label="PVDF膜" value="PVDF膜"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="重量:"
                prop="weight"
                v-if="myData.fillDemoInfoForm.demoState === '固体'"
              >
                <el-input
                  v-model="myData.fillDemoInfoForm.weight"
                  style="width: 240px"
                  placeholder="请填写"
                >
                  <span slot="append">mg</span>
                </el-input>
              </el-form-item>
              <template
                v-else-if="myData.fillDemoInfoForm.demoState === '液体'"
              >
                <el-form-item label="浓度:" prop="concentration">
                  <el-input
                    v-model="myData.fillDemoInfoForm.concentration"
                    style="width: 240px"
                    placeholder="请填写"
                  >
                    <span slot="append">mg/ml</span>
                  </el-input>
                </el-form-item>
                <el-form-item label="体积:" prop="volume">
                  <el-input
                    v-model="myData.fillDemoInfoForm.volume"
                    style="width: 240px"
                    placeholder="请填写"
                  >
                    <span slot="append">ml</span>
                  </el-input>
                </el-form-item>
              </template>
              <el-form-item
                label="凝胶类型:"
                prop="demoState"
                v-else-if="myData.fillDemoInfoForm.demoState === '凝胶'"
              >
                <el-select
                  v-model="myData.fillDemoInfoForm.gel"
                  placeholder="请选择"
                  style="width: 240px"
                >
                  <el-option label="1D银染" value="1D银染"></el-option>
                  <el-option label="1D考染" value="1D考染"></el-option>
                  <el-option label="2D银染" value="2D银染"></el-option>
                  <el-option label="2D考染" value="2D考染"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="PVDF膜类型:"
                prop="pvdf"
                v-else-if="myData.fillDemoInfoForm.demoState === 'PVDF膜'"
              >
                <el-select
                  v-model="myData.fillDemoInfoForm.pvdf"
                  placeholder="请选择"
                  style="width: 240px"
                >
                  <el-option label="考染" value="考染"></el-option>
                  <el-option label="丽春红" value="丽春红"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="实验备注:"
                prop="experimentalRequirements"
                class="product_wrap"
              >
                <el-input
                  type="textarea"
                  v-model="myData.fillDemoInfoForm.experimentalRequirements"
                  placeholder="最多不能超过500个汉字"
                  style="width: 500px"
                  class="remark"
                  maxlength="500"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="otherInfo">
          <el-form
            :model="myData.otherInfoForm"
            :rules="myData.otherInfoRules"
            ref="otherInfoForm"
            class="otherInfo_form"
            v-if="myData && myData.otherInfoForm"
          >
            <el-form-item label="是否加急:" prop="urgent">
              <el-radio-group
                v-model="myData.otherInfoForm.urgent"
                class="urgent_wrap"
              >
                <el-radio label="N">不加急</el-radio>
                <el-radio label="Y" class="urgent">
                  <span class="urgent_btn">
                    <span class="common">加急（</span>
                    <span class="common"
                      ><span class="red">申请加急，额外增加70%，请知晓；</span
                      >申请订单加急需要审批，如审批不通过，客服与您联系。）</span
                    >
                  </span>
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
        <div class="submit">
          <el-button type="primary" plain @click="saveForm">保存</el-button>
          <el-button plain @click="dialogOperation = false">取消</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { getProductDetection } from "@/api/product/index";
import { saveDraft } from "@/api/order/draft";
import { getUploadPath } from "@/api/file/index";
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    open: {
      type: Boolean,
      default: false,
    },
    productList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      dialogOperation: this.open, //操作弹窗
      myData: null, //详情数据
      originData: this.data, //原详情数据
      myProductList: this.productList, //产品列表
      //复制初始化的检测信息校验规则
      copyFillTestInfoRules: {
        product: [{ required: true, message: "请选择产品", trigger: "change" }],
        reportVersion: [
          { required: true, message: "请选择报告版本", trigger: "blur" },
        ],
        reportMedium: [
          { required: true, message: "请选择报告介质", trigger: "blur" },
        ],
        reportLanguage: [
          { required: true, message: "请选择报告语言", trigger: "blur" },
        ],
        testType: [
          { required: true, message: "请选择检测类别", trigger: "blur" },
        ],
        postType: [
          { required: true, message: "请选择送样方式", trigger: "blur" },
        ],
        handleRemain: [
          { required: true, message: "请选择余样处理方式", trigger: "blur" },
        ],
        hasRemain: [
          { required: true, message: "请选择是否留样", trigger: "blur" },
        ],
      },
      fileLists: [], //文件列表
      //上传文件请求头
      uploadHeaders: {
        Authorization: this.$utils.storage.get("user").token,
      },
      //上传图片参数
      uploadData: {
        type: 0,
      },
      //上传图片路径
      uploadAction: "",
      //
    };
  },
  watch: {
    open(newVal) {
      this.dialogOperation = newVal;
    },
    data: {
      handler(newVal) {
        this.originData = newVal;
        this.myData = JSON.parse(JSON.stringify(this.originData));
      },
      immediate: true,
    },
    "myData.fillTestInfoForm.product": function () {
      console.log("触发");
      this.handleGetProductDetection();
    },
  },
  methods: {
    // 获取上传图片接口地址
    handleGetUploadPath() {
      getUploadPath(this.uploadData).then((data) => {
        this.uploadAction = data;
      });
    },
    // 获取数据校验规则
    getRules(item) {
      const validate = {};
      validate.required = item.isNotNull === 1 ? true : false;
      if (item.fieldType === "text") {
        //输入框
        validate.trigger = "blur";
        validate.message = `请填写${item.fieldName}`;
      } else if (item.fieldType === "select") {
        //下拉框
        validate.trigger = "blur";
        validate.message = `请选择${item.fieldName}`;
      } else if (item.fieldType === "img") {
        //图片上传
        validate.trigger = "change";
        validate.message = `请上传${item.fieldName}`;
      }
      return validate;
    },
    // 获取选项列表
    getOptions(item) {
      if (!item) return;
      return item.split("|");
    },
    // 更新图片数据
    updateFile(file, fileList, item, index) {
      if (item.fieldType === "img") {
        const imgUrls = [];
        const files = [];
        fileList.forEach((item) => {
          //fileList里包含了返显数据，需要判断
          if (item.response && item.response.data && item.response.data.path) {
            imgUrls.push(item.response.data.path);
            files.push({
              url: item.response.data.path,
            });
          } else if (item.url) {
            imgUrls.push(item.url);
            files.push({
              url: item.url,
            });
          }
        });
        this.myData.fillTestInfoForm.dynamicDataList[index].value =
          imgUrls.join(",");
        this.fileLists[index] = files;
      }
    },
    // 获取产品动态检测信息字段
    handleGetProductDetection() {
      if (
        this.myData &&
        this.myData.fillTestInfoForm &&
        this.myData.fillTestInfoForm.product
      ) {
        const params = {
          productId: this.myData.fillTestInfoForm.product,
        };
        getProductDetection(params).then((res) => {
          //产品动态检测信息字段
          this.myData.fillTestInfoForm.detectionFields.splice(
            0,
            this.myData.fillTestInfoForm.detectionFields.length,
            ...res.detectionFields
          );
          const copy = { ...this.copyFillTestInfoRules };
          // 可以更新动态字段

          //检测要求
          this.myData.fillTestInfoForm.requirement = res.requirement;

          //更新用户填写动态检测信息字段
          const validate = [];
          //清空动态检测数据
          this.myData.fillTestInfoForm.dynamicDataList.splice(
            0,
            this.myData.fillTestInfoForm.dynamicDataList.length
          );
          //用户填写(产品动态检测信息字段)
          this.myData.fillTestInfoForm.detectionFields.forEach(
            (item, index) => {
              this.myData.fillTestInfoForm.dynamicDataList.push({
                fieldId: item.id,
                name: item.fieldName,
                value: "",
                fileList: [],
              });
              this.fileLists[index] = [];
              copy[`dynamicDataList[${index}].value`] = this.getRules(item); //添加一条动态检测数据校验规则
            }
          );
          this.myData.fillTestInfoRules = Object.assign(
            this.myData.fillTestInfoRules,
            copy
          ); //填写检测信息校验规则
        });
      }
    },
    // 关闭弹窗
    close() {
      this.$emit("close", this.dialogOperation);
    },
    // 获取提交草稿或订单的参数
    getParams() {
      const dynamicDataList = [...this.myData.fillTestInfoForm.dynamicDataList];
      dynamicDataList.forEach((item) => {
        delete item.fileList;
      });
      const params = {
        batchNo: this.myData.fillDemoInfoForm.demoNo, //样品批号
        concentration: this.myData.fillDemoInfoForm.concentration, //浓度；单位：mg/ml
        count: this.myData.fillDemoInfoForm.demoAmount, //样品数量
        dynamicDataList, //检测信息动态字段
        expiryDate: this.myData.fillDemoInfoForm.endDate, //有效期
        gelType:
          this.myData.fillDemoInfoForm.demoState === "凝胶"
            ? this.myData.fillDemoInfoForm.gel
            : "", //凝胶类型； 1：1D银染。2：1D考染。3：2D银染。4：2D考染
        isKeepingSample: this.myData.fillTestInfoForm.hasRemain, //是否留样；1：不留样。2：留样（委托方提供一式三份样品，独立包装，现场封签；否则，仅对此次测试负责）
        orderId: this.orderId ? this.orderId : undefined, //订单ID
        productId: this.myData.fillTestInfoForm.product
          ? this.myData.fillTestInfoForm.product
          : undefined, //产品id
        purity: this.myData.fillDemoInfoForm.purity, //纯度
        pvdf:
          this.myData.fillDemoInfoForm.demoState === "PVDF膜"
            ? this.myData.fillDemoInfoForm.pvdf
            : "", //PVDF膜；1：考染。2：丽春红
        remark: this.myData.fillTestInfoForm.remark, //备注
        reportLanguage: this.myData.fillTestInfoForm.reportLanguage, //报告语言；1：中文。
        reportMedium: this.myData.fillTestInfoForm.reportMedium, //报告介质；1：电子版。2：纸质版。3：两者皆需要。
        reportVersion: this.myData.fillTestInfoForm.reportVersion, //报告版本；1：CMA报告+APT报告。2：中科新生命版 (APT版)。
        residualSampleDispose: this.myData.fillTestInfoForm.handleRemain, //余样处理；1：不退样。2：退样
        sampleName: this.myData.fillDemoInfoForm.demoName, //样品名称
        samplePackage: this.myData.fillDemoInfoForm.demoPack, //样品包装；1：离心管。2：玻璃瓶。3：塑料容器。4：其他。
        sampleSendMethod: this.myData.fillTestInfoForm.postType, //送样方式；1：物流送样。2：客户送样。3：委托抽样（需另外支付费用）。
        sampleSource: this.myData.fillDemoInfoForm.demoOrigin, //样品来源；1：生产。2：购买（请提供原包装信息）。3：其他（备注里请明确）。
        sampleType: this.myData.fillDemoInfoForm.demoType, //样品类型；1：小肽类。2：重组蛋白。3：单抗类。4：其他类。
        specs: this.myData.fillDemoInfoForm.demoStandard, //样品规格；1：原料药。2：化合物。3：成品药。4：中间体。5：其他。
        state: this.myData.fillDemoInfoForm.demoState, //样品状态；1：固体。2：液体。3：凝胶。4：PVDF膜。
        storageConditions: this.myData.fillDemoInfoForm.storeCondition, //贮存条件；1：室温。2：2-8℃。3：-15至-25℃。4：-65至-85℃
        testType: this.myData.fillTestInfoForm.testType, //检测类别；1：委托。2：出口。3：复核。4：其他。
        trait: this.myData.fillDemoInfoForm.demoShape, //样品性状；1：无色澄清液体。2：淡黄色澄清液体。3：白色固体粉末。4：类白色固体粉末。5：其他。
        volume:
          this.myData.fillDemoInfoForm.demoState === "液体"
            ? this.myData.fillDemoInfoForm.volume
            : undefined, //体积；单位：ml
        weight:
          this.myData.fillDemoInfoForm.demoState === "固体"
            ? this.myData.fillDemoInfoForm.weight
            : undefined, //重量；单位：mg
        experimentalRequirements:
          this.myData.fillDemoInfoForm.experimentalRequirements, //实验备注
        urgent: this.myData.otherInfoForm.urgent, //是否加急
      };
      return params;
    },
    // 保存表单数据
    async saveForm() {
      // const params = this.getParams();
      // saveDraft(params).then((res) => {
      //   this.$message({
      //     type: "success",
      //     message: "已成功保存至草稿箱",
      //   });
      // });
      const result = await this.validate();
      if (result) {
        //验证通过
        const myData = JSON.parse(JSON.stringify(this.myData));
        this.originData = Object.assign(this.originData, myData);
        this.dialogOperation = false;
      }
    },
    async validate() {
      let result1 = undefined,
        result2 = undefined;
      await this.$refs.fillTestInfoForm.validate((valid) => {
        result1 = valid;
      });
      await this.$refs.fillDemoInfoForm.validate((valid) => {
        result2 = valid;
      });
      return result1 && result2;
    },
    // 图片上传成功回调
    uploadImg(response, file, fileList) {
      console.log(response, file, fileList);
    },
  },
  created() {
    this.handleGetUploadPath();
  },
};
</script>

<style lang="scss" scoped>
/*样式穿透*/
.dialogOperation {
  ::v-deep .create {
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    box-sizing: border-box;
    .fillTestInfo_wrap,
    .fillDemoInfo_wrap {
      .header {
        display: flex;
        align-items: center;
        .title {
          font-family: "Microsoft Tai Le Bold", "Microsoft Tai Le", sans-serif;
          font-weight: 700;
          font-style: normal;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.847058823529412);
          line-height: 24px;
        }
        .lineX {
          height: 1px;
          flex-grow: 1;
          border-top: 1px solid #e8e8e8;
          margin-left: 20px;
        }
      }
    }
    .submit {
      @include center;
      width: 100%;
      padding: 30px 0;
    }
    .fillTestInfo_wrap {
      .header {
        padding-bottom: 20px;
      }
    }
    .fillDemoInfo_wrap {
      flex-grow: 1;
      .header {
        padding: 20px 0;
      }
    }
    .el-input__inner {
      height: 36px;
      line-height: 36px;
    }
    .product_wrap {
      position: relative;
      width: 100%;
      .product_introduce {
        display: flex;
        flex-grow: 1;
      }
    }
  }
  ::v-deep .fillTestInfo_form,
  ::v-deep .fillDemoInfo_form {
    display: flex;
    flex-wrap: wrap;
    .el-form-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: auto;
      margin-right: 20px;
      .el-form-item__label {
        display: flex;
      }
      .el-form-item__content {
        margin-left: 0 !important;
      }
    }
    .product_wrap {
      width: 100%;
    }
    .add {
      font-size: 28px;
      color: #8c939d;
      font-style: normal;
      font-weight: 400;
      font-variant: normal;
      text-transform: none;
    }
    .product_wrap {
      position: relative;
      .product_introduce {
        display: flex;
        flex-grow: 1;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .TheortSeqImg {
      width: 240px;
      .el-upload-list--picture-card,
      .el-upload-list__item,
      .el-upload--picture-card {
        width: 94px;
        height: 94px;
      }
      .el-upload--picture-card {
        line-height: 94px;
      }
    }

    .remark {
      .el-textarea__inner {
        height: 72px;
      }
    }
  }
  ::v-deep .el-drawer__header > :first-child {
    font-family: "微软雅黑", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #333333;
    font-size: 18px;
  }

  /*样式穿透*/
  ::v-deep .otherInfo_form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  .urgent_wrap ::v-deep .urgent {
    .urgent_btn {
      .red {
        color: red;
      }
    }
  }
}
</style>
