import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import global from "./global";
import order from "./order";

Vue.use(Vuex);

export default new Vuex.Store({
  ...global,
  modules: {
    order,
  },
  plugins: [
    //数据持久化
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
});
