import request from "@/utils/request";

// 产品信息分页数据
export function productPage(data) {
  return request({
    url: "/apt-css/product/page",
    method: "post",
    data
  });
}

// 获取产品动态检测信息字段
export function getProductDetection(params) {
  return request({
    url: "/apt-css/product/getProductDetection",
    method: "get",
    params
  });
}

// 获取产品列表
export function listProduct(params) {
  return request({
    url: "/apt-css/product/listProduct",
    method: "get",
    params,
    loading: false
  });
}

