import storage from "./storage";
import validate from "./validate";
import map from "./map";
import "./init";
import { getConfigJsonAPI } from "@/api/home/index";
import { Message } from "element-ui";

/**
 * @param { data } data blob格式数据
 * @desc 在新页面打开一个文件流数据
 */
function openBlob(data) {
  const blob = new Blob([data], {
    type: "application/pdf;chartset=UTF-8",
  });
  const pdfUrl = window.URL.createObjectURL(blob);
  window.open(pdfUrl, "_blank");
  // 释放这个临时的对象url
  window.URL.revokeObjectURL(pdfUrl);
}

/*
 * @desc 更新版本。通过对比服务端与客户端的版本号判断是否需要更新
 */
export async function updateVersion() {
  if (process.env.NODE_ENV !== "production") return;
  const configJson = await getConfigJsonAPI();
  const serviceVersion = configJson.version; //服务端版本号
  const clientVersion = document
    .querySelector("#config")
    .content.split(",")
    .find((item) => item.includes("version="))
    .split("=")[1]; //客户端版本号
  if (serviceVersion && serviceVersion !== clientVersion) {
    Message.success("正在自动升级新版本...");
    setTimeout(() => window.location.reload(), 2000); // 版本不同 刷新 获取最新版本
  }
}

export default {
  storage,
  validate,
  map,
  openBlob,
};
