import request from "@/utils/request";

// 获取草稿详情
export function getDraftDetail(id) {
  return request({
    url: `/apt-css/draft/get/${id}`,
    method: "get",
  });
}

// 删除草稿
export function deleteDraft(id) {
  return request({
    url: `/apt-css/draft/delete/${id}`,
    method: "delete",
  });
}

// 复制草稿
export function copyDraft(data) {
  return request({
    url: `/apt-css/draft/copy`,
    method: "post",
    data,
  });
}

// 保存草稿
export function saveDraft(data) {
  return request({
    url: "/apt-css/draft/save",
    method: "post",
    data,
  });
}

// 提交草稿为订单
export function submitDraft(data) {
  return request({
    url: `/apt-css/draft/submit`,
    method: "post",
    data,
    timeout: 60000,
  });
}

// 获取草稿列表
export function getDraftList(params) {
  return request({
    url: `/apt-css/draft/page`,
    method: "get",
    params,
  });
}

// 获取草稿总数量
export function getDraftCount() {
  return request({
    url: `/apt-css/draft/getDraftCount`,
    method: "get",
  });
}
