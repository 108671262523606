export default {
  path: "/asg",
  name: "asg",
  component: () =>
    import(/* webpackChunkName: "asg" */ "@/views/asg/index/index.vue"),
  children: [
    {
      path: "order",
      component: () =>
        import(/* webpackChunkName: "asg" */ "@/views/asg/order/order.vue"),
    },
    {
      path: "draft",
      component: () =>
        import(/* webpackChunkName: "asg" */ "@/views/asg/draft/draft.vue"),
    },
  ],
};
