import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/iconfont/iconfont.css";
import "@/assets/styles/index.scss";
import _ from "lodash";
import "@/components/index.js";
import utils from "@/utils/index.js";
import "@/utils/permission";
import "@/assets/icons/index.js";
import * as filter from "@/utils/filter";
import {
  Button,
  Form,
  FormItem,
  Input,
  Tabs,
  TabPane,
  Select,
  Option,
  DatePicker,
  Table,
  TableColumn,
  Pagination,
  Dialog,
  Steps,
  Step,
  Checkbox,
  Popover,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Upload,
  Radio,
  Message,
  MessageBox,
  Drawer,
  Timeline,
  TimelineItem,
  Row,
  Col,
  RadioGroup,
  RadioButton,
  Descriptions,
  DescriptionsItem,
  Divider,
  Menu,
  MenuItem,
  Tooltip,
  InputNumber,
  Image,
	Tag,
	Empty,
	Skeleton,
	SkeletonItem,
	Loading
} from "element-ui";

Vue.config.productionTip = false;
Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Select);
Vue.use(Option);
Vue.use(DatePicker);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Checkbox);
Vue.use(Popover);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Upload);
Vue.use(Radio);
Vue.use(Drawer);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Row);
Vue.use(Col);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Descriptions);
Vue.use(DescriptionsItem);
Vue.use(Divider);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Tooltip);
Vue.use(InputNumber);
Vue.use(Image);
Vue.use(Tag);
Vue.use(Empty);
Vue.use(Skeleton);
Vue.use(SkeletonItem);
Vue.use(Loading);

Vue.prototype._ = _;
Vue.prototype.$message = Message;
Vue.prototype.$messageBox = MessageBox;
Vue.prototype.$utils = utils;

//注册全局过滤器
Object.keys(filter).forEach((key) => {
  Vue.filter(key, filter[key]);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
