export default {
  path: "/bsg",
  name: "bsg",
  component: () =>
    import(/* webpackChunkName: "bsg" */ "@/views/bsg/index/index.vue"),
  children: [
    {
      path: "otd",
      component: () =>
        import(/* webpackChunkName: "bsg" */ "@/views/bsg/otd/index/index.vue"),
      children: [
        {
          path: "order",
          component: () =>
            import(
              /* webpackChunkName: "bsg" */ "@/views/bsg/otd/order/order.vue"
            ),
        },
        {
          path: "draft",
          component: () =>
            import(
              /* webpackChunkName: "bsg" */ "@/views/bsg/otd/draft/draft.vue"
            ),
        },
        {
          path: "history",
          component: () =>
            import(
              /* webpackChunkName: "bsg" */ "@/views/bsg/otd/history/history.vue"
            ),
        },
        {
          path: "preview",
          component: () =>
            import(
              /* webpackChunkName: "bsg" */ "@/views/bsg/otd/preview/preview.vue"
            ),
        },
      ],
    },
    {
      path: "ltc",
      component: () =>
        import(/* webpackChunkName: "bsg" */ "@/views/bsg/ltc/index/index.vue"),
      children: [
        {
          path: "order",
          component: () =>
            import(
              /* webpackChunkName: "bsg" */ "@/views/bsg/ltc/order/order.vue"
            ),
        },
        {
          path: "draft",
          component: () =>
            import(
              /* webpackChunkName: "bsg" */ "@/views/bsg/ltc/draft/draft.vue"
            ),
        },
      ],
    },
  ],
};
