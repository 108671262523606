import request from "@/utils/request";

// 上传文件
export function getUploadPath(data) {
  return request({
    url: "/apt-css/file/getUploadPath",
    method: "get",
    data,
  });
}
