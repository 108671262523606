<template>
  <div class="info_wrap">
    <p class="title">报告寄送地址</p>
    <el-form
      :model="myData"
      :rules="rules"
      ref="form"
      label-width="100px"
      class="info_form"
      :label-position="labelPosition"
    >
      <el-checkbox
        v-model="equallyEntrust"
        @change="$emit('handleEquallyEntrust', equallyEntrust)"
        style="padding: 20px 0"
        >信息同上</el-checkbox
      >
      <el-form-item label="联系人" prop="reportsendpsn">
        <el-input
          v-model="myData.reportsendpsn"
          placeholder="请填写"
        />
      </el-form-item>
      <el-form-item label="联系电话" prop="reportsendtel">
        <el-input
          v-model="myData.reportsendtel"
          placeholder="请填写"
        />
      </el-form-item>
      <el-form-item label="邮箱地址" prop="reportsendemail">
        <el-input
          v-model="myData.reportsendemail"
          placeholder="请填写"
        />
      </el-form-item>
      <el-form-item label="详细地址" prop="reportsendaddress">
        <el-input
          v-model="myData.reportsendaddress"
          placeholder="请填写"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    myDisabled() {
      return this.disabled;
    },
  },
  data() {
    return {
      labelPosition: "top",
      myData: this.data,
      rules: {
        reportsendpsn: [
          { required: true, message: "请填写收件人", trigger: "blur" },
        ],
        reportsendtel: [
          { required: true, message: "请填写联系电话", trigger: "blur" },
        ],
        reportsendaddress: [
          { required: true, message: "请填写详细地址", trigger: "blur" },
        ],
      },
      equallyEntrust: false, //地址是否同上？
    };
  },
  methods: {
    async validate() {
      let result = undefined;
      await this.$refs.form.validate((valid) => {
        result = valid;
      });
      return result;
    },
    resetFields() {
      this.$refs.form.resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.info_wrap {
  .title {
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: 600;
  }
}

/*样式穿透*/
.otherInfo ::v-deep .info_form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.urgent_wrap ::v-deep .urgent {
  .urgent_btn {
    .red {
      color: red;
    }
  }
}
</style>
