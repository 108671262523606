<template>
  <div class="otherInfo_wrap">
    <p class="title">加急需求</p>
    <el-form
      :model="myData"
      :rules="otherInfoRules"
      ref="otherInfoForm"
      label-width="100px"
      class="otherInfo_form"
      :label-position="labelPosition"
    >
      <!-- 内部用户才可以填写项目号 -->
      <el-form-item
        v-if="user.userType === 1"
        label="项目方案号"
        prop="projectCode"
        class="projectCode"
      >
        <el-select
          v-model="myData.projectCode"
          filterable
          placeholder="请输入关键词"
          :filter-method="handleSearchProject"
          :loading="project.loading"
          :disabled="myDisabled"
          clearable
          style="width: 100%"
        >
          <el-option
            v-for="item of project.list"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
            <span style="float: left">{{ item.project_name }}</span>
            <span style="float: right">{{ item.project_code }}</span>
          </el-option>
          <span
            v-if="project.more"
            @click="searchMoreProject()"
            style="
              display: flex;
              width: 100%;
              height: 50px;
              justify-content: center;
              align-items: center;
              color: #1890ff;
              font-weight: bold;
              cursor: pointer;
            "
            >加载更多</span
          >
          <span
            v-else
            style="
              display: flex;
              width: 100%;
              height: 50px;
              justify-content: center;
              align-items: center;
              font-weight: bold;
              color: #606266;
            "
            >没有更多数据了</span
          >
        </el-select>
      </el-form-item>

      <!-- 内部用户填写下单人 -->
      <el-form-item
        v-if="user.userType === 1"
        label="内部用户（下单人）"
        prop="name"
      >
        <el-select
          v-model="myData.name"
          placeholder="输入姓名，自动检索"
          @change="changeUser"
          :filter-method="getUserList"
          :loading="userInfo.loading"
          style="width: 100%"
          :disabled="myDisabled"
          filterable
          clearable
        >
          <el-option
            v-for="item in userInfo.list"
            :key="`${item.code},${item.userId}`"
            :label="item.name"
            :value="`${item.code},${item.userId}`"
          >
            <span style="float: left">{{ item.name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{
              item.deptName
            }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="是否加急" prop="urgent">
        <el-radio-group
          v-model="myData.urgent"
          :disabled="myDisabled"
          class="urgent_wrap"
          @change="changeUrgent"
        >
          <el-radio label="N">不加急</el-radio>
          <el-radio label="Y" class="urgent">
            <span class="urgent_btn">
              <span class="common">加急（</span>
              <span class="common"
                ><span class="red"
                  >加急将额外增加70%的费用，申请加急后不予取消，请谨慎申请加急；</span
                >申请订单加急需要审批，如审批不通过，客服将与您联系。）</span
              >
            </span>
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="myData.urgent === 'Y'"
        label="期望完成日期"
        prop="expectfintime"
      >
        <el-date-picker
          v-model="myData.expectfintime"
          type="date"
          placeholder="选择日期"
          style="width: 240px"
          :pickerOptions="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getInnerProject, listSysUser } from "@/api/order/bsg/otd";

export default {
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    myDisabled() {
      return this.disabled;
    },
  },
  data() {
    return {
      labelPosition: "top",
      myData: this.data,
      otherInfoRules: {
        urgent: [
          { required: true, message: "请选择是否加急", trigger: "blur" },
        ],
        projectCode: [
          { required: true, message: "请填写项目号", trigger: "blur" },
        ],
        expectfintime: [
          { required: true, message: "请填写期望日期", trigger: "blur" },
        ],
        name: [
          {
            required: true,
            message: "请选择内部用户（下单人）",
            trigger: "blur",
          },
        ],
      },
      user: this.$utils.storage.get("user"), // 用户数据
      project: {
        loading: false, //加载状态
        list: [], //列表
        pageSize: 10, //页尺寸
        pageNum: 1, //页码
        more: true, //是否可以加载更多数据
        query: "", //当前查询值
      }, //项目方案
      userInfo: {
        //内部下单用户信息
        loading: false, //加载状态
        list: [], //列表
      },
      pickerOptions: {
        disabledDate(time) {
          //禁用日期
          const curTime = new Date().getTime(); //当前时间
          if (time) {
            const selectTime = new Date(time).getTime(); //选择的时间
            return selectTime < curTime;
          } else {
            return false;
          }
        },
      },
    };
  },
  methods: {
    // 改变项目搜索方案
    changeProject(val) {
      this.myData.projectCode = val;
    },
    // 搜索项目方案
    handleSearchProject: _.debounce(function (query) {
      this.project.more = true;
      this.project.query = query;
      if (query) {
        this.searchProject(query);
      } else {
        this.project.list = [];
      }
    }, 300),
    // 搜索更多项目方案
    searchMoreProject: _.debounce(function () {
      this.project.loading = true;
      this.project.more = true;
      this.project.pageNum += 1;
      const params = {
        params: {
          pageSize: this.project.pageSize,
          pageNum: this.project.pageNum,
          projectCode: this.project.query, //项目编码
        },
        loading: false,
      };
      getInnerProject(params)
        .then((data) => {
          if (data.projectList && data.projectList.length === 0) {
            this.project.more = false;
          } else {
            const list = data.projectList.map((item) => {
              return {
                label: item.project_name,
                value: item.project_code,
                ...item,
              };
            });
            this.project.list = [...this.project.list, ...list];
          }
        })
        .finally(() => {
          this.project.loading = false;
        });
    }, 300),
    // 搜索项目方案
    searchProject(query) {
      this.project.loading = true;
      this.project.more = true;
      this.project.pageNum = 1;
      const params = {
        params: {
          pageSize: this.project.pageSize,
          pageNum: this.project.pageNum,
          projectCode: query, //项目编码
        },
        loading: false,
      };
      getInnerProject(params)
        .then((data) => {
          if (data.projectList && data.projectList.length === 0) {
            this.project.more = false;
          } else {
            this.project.list = data.projectList.map((item) => {
              return {
                label: item.project_name,
                value: item.project_code,
                ...item,
              };
            });
          }
        })
        .finally(() => {
          this.project.loading = false;
        });
    },
    async validate() {
      let result = undefined;
      await this.$refs.otherInfoForm.validate((valid) => {
        result = valid;
      });
      return result;
    },
    resetFields() {
      this.$refs.otherInfoForm.resetFields();
    },
    //改变加急状态
    changeUrgent(val) {
      if (val === "Y") {
        this.otherInfoRules.expectfintime[0].required = true;
      } else if (val === "N") {
        this.otherInfoRules.expectfintime[0].required = false;
        this.myData.expectfintime = "";
      }
    },
    //获取内部下单用户数据
    getUserList(name) {
      this.userInfo.loading = true;
      const params = {
        params: {
          name: name, //用户姓名
        },
        loading: true,
      };
      listSysUser(params)
        .then((data) => {
          this.userInfo.list = data.map((item) => {
            return {
              label: item.name,
              value: `${item.code},${item.userId}`,
              ...item,
            };
          });
        })
        .finally(() => {
          this.userInfo.loading = false;
        });
    },
    changeUser(item) {
      const arr = item.split(",");
      this.myData.code = arr[0];
      this.myData.userId = arr[1];
      console.log(this.myData);
    },
    //查询对应的内部下单用户数据
    getUser(params) {
      const params2 = {
        params: {
          code: params.code,
          userId: params.userId,
        },
        loading: true,
      };
      listSysUser(params2)
        .then((data) => {
          this.userInfo.list = data.map((item) => {
            return {
              label: item.name,
              value: `${item.code},${item.userId}`,
              ...item,
            };
          });
          this.myData.name = data[0].name;
        })
        .finally(() => {
          this.userInfo.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.otherInfo_wrap {
  .title {
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: 600;
  }
}

/*样式穿透*/
.otherInfo ::v-deep .otherInfo_form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.urgent_wrap ::v-deep .urgent {
  .urgent_btn {
    .red {
      color: red;
    }
  }
}
</style>
