export default {
  path: "/",
  component: () =>
    import(/* webpackChunkName: "home" */ "@/views/home/index/index.vue"),
  redirect: "/home",
  children: [
    {
      path: "home",
      name: "home",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/home/home/home.vue"),
    },
		{
		  path: "forget",
		  name: "forget",
		  component: () =>
		    import(/* webpackChunkName: "home" */ "@/views/home/home/forget.vue"),
		}
  ],
};
