import request from "@/utils/request";

// 获取验证码图片
export function getCaptcha(params) {
  return request({
    url: "/apt-user/user/getCaptcha",
    method: "get",
    loading: false,
    params,
  });
}

// 登录
export function login(data) {
  return request({
    url: "/apt-user/user/login",
    method: "post",
    data,
  });
}

// 修改密码
export function modifyPassword(data) {
  return request({
    url: "/apt-user/user/changePassword",
    method: "put",
    data,
  });
}

// 发送短信/邮件 验证码
export function sendSms(data) {
  return request({
    url: "/apt-user/user/sendSms",
    method: "post",
    data,
  });
}

// 找回密码
export function retrievePassword(data) {
  return request({
    url: "/apt-user/user/retrievePassword",
    method: "post",
    data,
  });
}

// 修改默认密码
export function changeDefaultPassword(data) {
  return request({
    url: "/apt-user/user/changeDefaultPassword",
    method: "put",
    data,
  });
}

//配置
export function getConfigJsonAPI(params) {
  return request({
    method: "get",
    url: `/config.json?v=${new Date().getTime()}`,
    headers: { "Cache-Control": "no-cache" },
    baseURL: window.location.origin,
    params
  });
}