<template>
  <div class="create_wrap elTab">
    <!-- <el-tabs
      v-model="active"
      @tab-click="handleClick"
      class="order"
      v-if="!myData.id"
    >
      <el-tab-pane label="新建订单" name="/order/create"></el-tab-pane>
    </el-tabs> -->
    <div class="create">
      <fill-test-info
        :data="fillTestInfoForm"
        :updateDDL="updateDDL"
        ref="fillTestInfo"
        :disabled="myData.disabled"
      />
      <fill-demo-info
        :data="fillDemoInfoForm"
        :updateDDL="updateDDL"
        ref="fillDemoInfo"
        :disabled="myData.disabled"
      />
      <other-info
        :data="otherInfoForm"
        ref="otherInfo"
        :disabled="myData.disabled"
      />
      <entrust-info
        v-if="user.userType === 0"
        :data="entrustInfoForm"
        ref="entrustInfo"
        :disabled="myData.disabled"
      />
      <report-info
        v-if="user.userType === 0"
        :data="reportInfoForm"
        ref="reportInfo"
        :disabled="myData.disabled"
        @handleEquallyEntrust="handleEquallyEntrust"
      />
    </div>
    <div class="submit" v-if="myData.type !== 'viewDetail'">
      <el-button
        type="primary"
        style="width: 200px"
        @click.native="handleSubmitForm"
        >提 交</el-button
      >
      <el-button @click.native="resetForm">重置</el-button>
      <el-button @click="handleSaveInfo">保存至草稿箱</el-button>
      <el-button @click="dialogEntrust = true">委托协议模板</el-button>
      <!-- <el-button style="height: 36px;" plain @click="$emit('cancel')">取消</el-button> -->
    </div>

    <apt-entrust-protocol v-model="dialogEntrust" />
  </div>
</template>

<script>
import { getBsgOtdOrderDetail } from "@/api/order/index";
import { getDraftDetail } from "@/api/order/draft";
import { saveDraft, submitDraft } from "@/api/order/draft";
import { listProduct } from "@/api/product/index";
import fillTestInfo from "./fillTestInfo.vue";
import fillDemoInfo from "./fillDemoInfo.vue";
import otherInfo from "./otherInfo.vue";
import entrustInfo from "./entrustInfo.vue";
import reportInfo from "./reportInfo.vue";
import { order as dictOrder} from "@/utils/dict"
export default {
  name: "create",
  components: {
    fillTestInfo,
    fillDemoInfo,
    otherInfo,
    entrustInfo,
    reportInfo,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      tabPosition: "left",
      active: "/order/create", //当前激活页
      myData: this.data, //作为一个组件时获取到的参数
      //检测信息
      fillTestInfoForm: {
        product: "", //产品
        productList: [], //产品列表
        requirement: "", //检测要求
        detectionFields: [], //产品动态检测信息字段(查询数据)
        dynamicDataList: [], //产品动态检测信息字段(用户填写)
        reportVersion: "", //报告版本
        reportMedium: "", //报告介质
        reportLanguage: "中文", //报告语言
        testType: "", //检测类别
        postType: "", //送样方式
        handleRemain: "", //余样处理
        hasRemain: "", //是否留样
        remark: "", //备注
      },
      //样品信息
      fillDemoInfoForm: {
        demoType: "", //样品类型
        demoOrigin: "", //样品来源
        demoName: "", //样品名称
        demoNo: "", //样品批号
        demoAmount: 1, //样品数量
        demoShape: "", //样品性状
        purity: "", //纯度
        endDate: "", //截止有效期
        demoStandard: "", //样品规格
        saveCondition: "", //保存条件
        demoPack: "", //样品包装
        demoState: "", //样品状态
        weight: "", //重量
        concentration: "", //浓度
        volume: "", //体积
        gel: "", //凝胶类型
        pvdf: "", //PVDF膜类型
        experimentalRequirements: "", //实验备注
      },
      //其他信息
      otherInfoForm: {
        urgent: "N", //是否加急,N-加急，Y-不加急
        projectCode: "", //项目号
        expectfintime: "", //期望完成日期
        code: "", //部门编码
        userId: "", //用户ID
        name: "", //用户姓名
      },
      //委托协议寄送地址信息
      entrustInfoForm: {
        entrustsendpsn: "", //收件人
        entrustsendtel: "", //联系电话
        entrustsendaddress: "", //详细地址
        entrustsendemail: "", //邮箱
      },
      //报告寄送地址信息
      reportInfoForm: {
        reportsendpsn: "", //收件人
        reportsendtel: "", //联系电话
        reportsendaddress: "", //详细地址
        reportsendemail: "", //邮箱
      },
      copyInitForm: {
        fillTestInfoForm: {},
        fillDemoInfoForm: {},
        otherInfoForm: {},
        entrustInfoForm: {},
        reportInfoForm: {},
      }, //复制初始的表单数据
      dialogEntrust: false, //委托协议弹窗
      orderId: "", //保存草稿订单编号
      updateDDL: true, //默认用户检测动态字段是可以更新的
      user: this.$utils.storage.get("user"), // 用户数据
    };
  },
  watch: {
    data(newVal) {
      this.myData = newVal;
      this.getQuery();
    },
  },
  methods: {
    // 加载地址是否跟委托协议相同
    handleEquallyEntrust(val) {
      if (val) {
        this.reportInfoForm.reportsendpsn = this.entrustInfoForm.entrustsendpsn;
        this.reportInfoForm.reportsendtel = this.entrustInfoForm.entrustsendtel;
        this.reportInfoForm.reportsendaddress =
          this.entrustInfoForm.entrustsendaddress;
        this.reportInfoForm.reportsendemail =
          this.entrustInfoForm.entrustsendemail;
      }
    },
    // 复制初始的表单数据
    handleCopyInitForm() {
      this.copyInitForm.fillTestInfoForm = JSON.parse(
        JSON.stringify(this.fillTestInfoForm)
      );
      this.copyInitForm.fillDemoInfoForm = JSON.parse(
        JSON.stringify(this.fillDemoInfoForm)
      );
      this.copyInitForm.otherInfoForm = JSON.parse(
        JSON.stringify(this.otherInfoForm)
      );
      this.copyInitForm.entrustInfoForm = JSON.parse(
        JSON.stringify(this.entrustInfoForm)
      );
      this.copyInitForm.reportInfoForm = JSON.parse(
        JSON.stringify(this.reportInfoForm)
      );
    },
    // 提示用户是否保存数据
    isSaveData(done) {
      //当前表单数据
      const curForm = JSON.stringify({
        fillTestInfoForm: this.fillTestInfoForm,
        fillDemoInfoForm: this.fillDemoInfoForm,
        otherInfoForm: this.otherInfoForm,
        entrustInfoForm: this.entrustInfoForm,
        reportInfoForm: this.reportInfoForm,
      });
      //初始表单数据
      const initForm = JSON.stringify(this.copyInitForm);
      //判断当前表单数据和初始表单数据是否相等
      if (curForm !== initForm) {
        this.$messageBox({
          type: "warning",
          message: "是否保存至草稿箱？",
        });
        this.$messageBox
          .confirm("是否保存至草稿箱？", "提示", {
            confirmButtonText: "保存",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            this.handleSaveInfo();
            this.$emit("close");
          })
          .catch(() => {
            done();
            this.$emit("close");
          });
      } else {
        done();
        this.$emit("close");
      }
    },
    // 重置表单
    resetForm() {
      this.$refs.fillTestInfo.resetFields();
      this.$refs.fillDemoInfo.resetFields();
      this.$refs.otherInfo.resetFields();
      this.$refs.entrustInfo.resetFields();
      this.$refs.reportInfo.resetFields();
    },
    handleClick(tab, event) {
      this.$router.push(`${this.active}`);
    },
    handleSubmitForm: _.debounce(async function () {
      const checkResult = await this.checkForm();
      if (checkResult) {
        this.submitForm();
      } else {
        this.$message({
          type: "error",
          message: "有必填项字段未填写或填写错误，请检查表单！",
        });
      }
    }, 300),
    async checkForm() {
      const validate1 = this.$refs.fillTestInfo.validate();
      const validate2 = this.$refs.fillDemoInfo.validate();
      const validate3 = this.$refs.otherInfo.validate();
      let validate4 = true,
        validate5 = true;
      //外部用户进行校验
      if (this.user.userType === 0) {
        validate4 = this.$refs.entrustInfo.validate();
        validate5 = this.$refs.reportInfo.validate();
      }
      const result = await Promise.all([
        validate1,
        validate2,
        validate3,
        validate4,
        validate5,
      ]);
      return result[0] && result[1] && result[2] && result[3] && result[4];
    },
    // 提交订单
    submitForm() {
      const params = this.getParams();
      submitDraft(params).then((res) => {
        this.$message({
          type: "success",
          message: "已成功提交订单",
        });
        this.$emit("success");
      });
    },
    // 获取提交草稿或订单的参数
    getParams() {
      const dynamicDataList = JSON.parse(
        JSON.stringify(this.fillTestInfoForm.dynamicDataList)
      );
      dynamicDataList.forEach((item) => {
        delete item.fileList;
      });
      const params = {
        batchNo: this.fillDemoInfoForm.demoNo, //样品批号
        concentration: this.fillDemoInfoForm.concentration, //浓度；单位：mg/ml
        count: this.fillDemoInfoForm.demoAmount, //样品数量
        dynamicDataList, //检测信息动态字段
        expiryDate: this.fillDemoInfoForm.endDate, //有效期
        gelType:
          this.fillDemoInfoForm.demoState === "凝胶"
            ? this.fillDemoInfoForm.gel
            : "", //凝胶类型； 1：1D银染。2：1D考染。3：2D银染。4：2D考染
        isKeepingSample: this.fillTestInfoForm.hasRemain, //是否留样；1：不留样。2：留样（委托方提供一式三份样品，独立包装，现场封签；否则，仅对此次测试负责）
        orderId: this.orderId ? this.orderId : undefined, //订单ID
        productId: this.fillTestInfoForm.product
          ? this.fillTestInfoForm.product
          : undefined, //产品id
        purity: this.fillDemoInfoForm.purity, //纯度
        pvdf:
          this.fillDemoInfoForm.demoState === "PVDF膜"
            ? this.fillDemoInfoForm.pvdf
            : "", //PVDF膜；1：考染。2：丽春红
        remark: this.fillTestInfoForm.remark, //备注
        reportLanguage: this.fillTestInfoForm.reportLanguage, //报告语言；1：中文。
        reportMedium: this.fillTestInfoForm.reportMedium, //报告介质；1：电子版。2：纸质版。3：两者皆需要。
        reportVersion: this.fillTestInfoForm.reportVersion, //报告版本；1：CMA报告+APT报告。2：中科新生命版 (APT版)。
        residualSampleDispose: this.fillTestInfoForm.handleRemain, //余样处理；1：不退样。2：退样
        sampleName: this.fillDemoInfoForm.demoName, //样品名称
        samplePackage: this.fillDemoInfoForm.demoPack, //样品包装；1：离心管。2：玻璃瓶。3：塑料容器。4：其他。
        sampleSendMethod: this.fillTestInfoForm.postType, //送样方式；1：物流送样。2：客户送样。3：委托抽样（需另外支付费用）。
        sampleSource: this.fillDemoInfoForm.demoOrigin, //样品来源；1：生产。2：购买（请提供原包装信息）。3：其他（备注里请明确）。
        sampleType: this.fillDemoInfoForm.demoType, //样品类型；1：小肽类。2：重组蛋白。3：单抗类。4：其他类。
        specs: this.fillDemoInfoForm.demoStandard, //样品规格；1：原料药。2：化合物。3：成品药。4：中间体。5：其他。
        state: this.fillDemoInfoForm.demoState, //样品状态；1：固体。2：液体。3：凝胶。4：PVDF膜。
        storageConditions: this.fillDemoInfoForm.saveCondition, //贮存条件；1：室温。2：2-8℃。3：-15至-25℃。4：-65至-85℃
        testType: this.fillTestInfoForm.testType, //检测类别；1：委托。2：出口。3：复核。4：其他。
        trait: this.fillDemoInfoForm.demoShape, //样品性状；1：无色澄清液体。2：淡黄色澄清液体。3：白色固体粉末。4：类白色固体粉末。5：其他。
        volume:
          this.fillDemoInfoForm.demoState === "液体"
            ? this.fillDemoInfoForm.volume
            : undefined, //体积；单位：ml
        weight:
          this.fillDemoInfoForm.demoState === "固体"
            ? this.fillDemoInfoForm.weight
            : undefined, //重量；单位：mg
        experimentalRequirements:
          this.fillDemoInfoForm.experimentalRequirements, //实验备注
        urgent: this.otherInfoForm.urgent, //是否加急
        projectCode: this.otherInfoForm.projectCode, //项目号
        deptCode: this.otherInfoForm.code, //部门编码
        sysUserId: this.otherInfoForm.userId, //员工ID

        expectfintime: this.otherInfoForm.expectfintime, //期望完成时间

        entrustsendpsn: this.entrustInfoForm.entrustsendpsn, //收件人
        entrustsendtel: this.entrustInfoForm.entrustsendtel, //联系电话
        entrustsendaddress: this.entrustInfoForm.entrustsendaddress, //详细地址
        entrustsendemail: this.entrustInfoForm.entrustsendemail, //邮箱

        reportsendpsn: this.reportInfoForm.reportsendpsn, //收件人
        reportsendtel: this.reportInfoForm.reportsendtel, //联系电话
        reportsendaddress: this.reportInfoForm.reportsendaddress, //详细地址
        reportsendemail: this.reportInfoForm.reportsendemail, //邮箱
      };
      return params;
    },
    // 加载保存草稿
    handleSaveInfo: _.debounce(async function () {
      const errMsg = await this.checkSaveInfoForm();
      if (errMsg) {
        this.$message({
          type: "error",
          message: "有必填项字段未填写或填写错误，请检查表单！",
        });
        return;
      } else {
        this.saveInfo();
      }
    }, 300),
    // 保存草稿表单校验
    async checkSaveInfoForm() {
      const errMsg = await this.$refs.fillTestInfo.validateField("product");
      const validateRules = [
        {
          value: "固体",
          key: ["weight"],
        },
        {
          value: "液体",
          key: ["concentration", "volume"],
        },
      ];
      let validateKey =
        validateRules.find(
          (item) => item.value === this.fillDemoInfoForm.demoState
        )?.key ?? [];
      const errMsg2 = await this.$refs.fillDemoInfo.validateField(validateKey);
      return errMsg || errMsg2;
    },
    // 保存草稿
    async saveInfo() {
      const params = this.getParams();
      saveDraft(params).then((res) => {
        this.orderId = res.order.id;
        this.$message({
          type: "success",
          message: "已成功保存至草稿箱",
        });
        this.$emit("success");
      });
    },
    // 提交并确认订单
    submitConfirmAndForm() {},
    // 获取参数
    async getQuery() {
      if (this.myData.type === "modifyDraft" && this.myData.id) {
        //修改草稿
        await this.handleGetDraftDetail(this.myData.id);
        if (this.otherInfoForm.code && this.otherInfoForm.userId) {
          this.$refs.otherInfo.getUser(this.otherInfoForm);
        }
      } else if (
        this.myData.type === "createOrderQuickly" &&
        this.myData.product
      ) {
        //快速下单
        this.fillTestInfoForm.product = this.myData.product;
      } else if (this.myData.type === "submitAgain" && this.myData.id) {
        //驳回再提交
        this.handleGetOrderDetail(this.myData.id);
      } else if (this.myData.type === "viewDetail" && this.myData.id) {
        //查看详情
        this.handleGetOrderDetail(this.myData.id);
      } else if (this.myData.type === "createOrder") {
        //新建订单
        //获取委托和报告数据，初始化表单。
        this.initForm();
      }
    },
    //获取委托和报告数据，初始化表单。
    initForm() {
      this.entrustInfoForm.entrustsendpsn = this.myData.entrustsendpsn;
      this.entrustInfoForm.entrustsendtel = this.myData.entrustsendtel;
      this.entrustInfoForm.entrustsendaddress = this.myData.entrustsendaddress;
      this.entrustInfoForm.entrustsendemail = this.myData.entrustsendemail;

      this.reportInfoForm.reportsendpsn = this.myData.reportsendpsn;
      this.reportInfoForm.reportsendtel = this.myData.reportsendtel;
      this.reportInfoForm.reportsendaddress = this.myData.reportsendaddress;
      this.reportInfoForm.reportsendemail = this.myData.reportsendemail;
    },
    // 获取草稿详情
    async handleGetDraftDetail(id) {
      const { ordSample, ordDetection, dynamicDataList, proProduct, order } =
        await getDraftDetail(id);
      //检测信息
      this.fillTestInfoForm.product = order.productId; //产品ID
      this.fillTestInfoForm.requirement =
        proProduct && proProduct.requirement ? proProduct.requirement : "";
      this.fillTestInfoForm.dynamicDataList = dynamicDataList; //用户填写动态检测
      this.fillTestInfoForm.reportVersion = ordDetection.reportVersion;
      this.fillTestInfoForm.reportMedium = ordDetection.reportMedium;
      this.fillTestInfoForm.reportLanguage = ordDetection.reportLanguage;
      this.fillTestInfoForm.testType = ordDetection.testType;
      this.fillTestInfoForm.postType = ordDetection.sampleSendMethod;
      this.fillTestInfoForm.handleRemain = ordDetection.residualSampleDispose;
      this.fillTestInfoForm.hasRemain = ordDetection.isKeepingSample;
      this.fillTestInfoForm.remark = ordDetection.remark;
      //样品信息
      const target = dictOrder.sampleTypes.find(item => item.value === ordSample.sampleType)
      this.fillDemoInfoForm.demoType = target?.value ?? "其他"
      this.fillDemoInfoForm.demoOrigin = ordSample.sampleSource;
      this.fillDemoInfoForm.demoName = ordSample.sampleName;
      this.fillDemoInfoForm.demoNo = ordSample.batchNo;
      this.fillDemoInfoForm.demoAmount = Number(ordSample.count);
      this.fillDemoInfoForm.demoShape = ordSample.trait;
      this.fillDemoInfoForm.purity = ordSample.purity;
      this.fillDemoInfoForm.endDate = ordSample.expiryDate;
      this.fillDemoInfoForm.demoStandard = ordSample.specs;
      this.fillDemoInfoForm.saveCondition = ordSample.storageConditions;
      this.fillDemoInfoForm.demoPack = ordSample.samplePackage;
      this.fillDemoInfoForm.demoState = ordSample.state;
      this.fillDemoInfoForm.concentration = ordSample.concentration;
      this.fillDemoInfoForm.volume = ordSample.volume;
      this.fillDemoInfoForm.weight = ordSample.weight;
      this.fillDemoInfoForm.gel = ordSample.gelType;
      this.fillDemoInfoForm.pvdf = ordSample.pvdf;
      this.fillDemoInfoForm.experimentalRequirements =
        ordDetection.experimentalRequirements;

      //其它信息
      this.otherInfoForm.code = order.deptCode ? order.deptCode : "";
      this.otherInfoForm.userId = order.sysUserId;

      this.otherInfoForm.urgent = order.urgent ? order.urgent : "N";
      this.otherInfoForm.projectCode = order.projectCode
        ? order.projectCode
        : "";
      this.otherInfoForm.expectfintime = order.expectfintime
        ? order.expectfintime
        : "";

      //委托协议信息
      this.entrustInfoForm.entrustsendpsn = order.entrustsendpsn
        ? order.entrustsendpsn
        : "";
      this.entrustInfoForm.entrustsendtel = order.entrustsendtel
        ? order.entrustsendtel
        : "";
      this.entrustInfoForm.entrustsendaddress = order.entrustsendaddress
        ? order.entrustsendaddress
        : "";
      this.entrustInfoForm.entrustsendemail = order.entrustsendemail
        ? order.entrustsendemail
        : "";

      //报告信息
      this.reportInfoForm.reportsendpsn = order.reportsendpsn
        ? order.reportsendpsn
        : "";
      this.reportInfoForm.reportsendtel = order.reportsendtel
        ? order.reportsendtel
        : "";
      this.reportInfoForm.reportsendaddress = order.reportsendaddress
        ? order.reportsendaddress
        : "";
      this.reportInfoForm.reportsendemail = order.reportsendemail
        ? order.reportsendemail
        : "";

      this.orderId = order.id; //订单ID
      this.updateDDL = false; //修改功能首次不更新动态字段
    },
    // 获取订单产品详情
    async handleGetOrderDetail(id) {
      const { ordSample, ordDetection, dynamicDataList, proProduct, order } =
        await getBsgOtdOrderDetail(id);
      //检测信息
      this.fillTestInfoForm.product = proProduct.productId; //产品ID
      this.fillTestInfoForm.requirement =
        proProduct && proProduct.requirement ? proProduct.requirement : "";
      this.fillTestInfoForm.dynamicDataList = dynamicDataList; //用户填写动态检测
      this.fillTestInfoForm.reportVersion = ordDetection.reportVersion;
      this.fillTestInfoForm.reportMedium = ordDetection.reportMedium;
      this.fillTestInfoForm.reportLanguage = ordDetection.reportLanguage;
      this.fillTestInfoForm.testType = ordDetection.testType;
      this.fillTestInfoForm.postType = ordDetection.sampleSendMethod;
      this.fillTestInfoForm.handleRemain = ordDetection.residualSampleDispose;
      this.fillTestInfoForm.hasRemain = ordDetection.isKeepingSample;
      this.fillTestInfoForm.remark = ordDetection.remark;
      //样品信息
      const target = dictOrder.sampleTypes.find(item => item.value === ordSample.sampleType)
      this.fillDemoInfoForm.demoType = target?.value ?? "其他"
      this.fillDemoInfoForm.demoOrigin = ordSample.sampleSource;
      this.fillDemoInfoForm.demoName = ordSample.sampleName;
      this.fillDemoInfoForm.demoNo = ordSample.batchNo;
      this.fillDemoInfoForm.demoAmount = Number(ordSample.count);
      this.fillDemoInfoForm.demoShape = ordSample.trait;
      this.fillDemoInfoForm.purity = ordSample.purity;
      this.fillDemoInfoForm.endDate = ordSample.expiryDate;
      this.fillDemoInfoForm.demoStandard = ordSample.specs;
      this.fillDemoInfoForm.saveCondition = ordSample.storageConditions;
      this.fillDemoInfoForm.demoPack = ordSample.samplePackage;
      this.fillDemoInfoForm.demoState = ordSample.state;
      this.fillDemoInfoForm.concentration = ordSample.concentration;
      this.fillDemoInfoForm.volume = ordSample.volume;
      this.fillDemoInfoForm.weight = ordSample.weight;
      this.fillDemoInfoForm.gel = ordSample.gelType;
      this.fillDemoInfoForm.pvdf = ordSample.pvdf;
      this.fillDemoInfoForm.experimentalRequirements =
        ordDetection.experimentalRequirements;

      //其它信息
      this.otherInfoForm.code = order.deptCode ? order.deptCode : "";
      this.otherInfoForm.userId = order.sysUserId;

      this.otherInfoForm.urgent = order.urgent ? order.urgent : "N";
      this.otherInfoForm.projectCode = order.projectCode
        ? order.projectCode
        : "";
      this.otherInfoForm.expectfintime = order.expectfintime
        ? order.expectfintime
        : "";

      //委托协议信息
      this.entrustInfoForm.entrustsendpsn = order.entrustsendpsn
        ? order.entrustsendpsn
        : "";
      this.entrustInfoForm.entrustsendtel = order.entrustsendtel
        ? order.entrustsendtel
        : "";
      this.entrustInfoForm.entrustsendaddress = order.entrustsendaddress
        ? order.entrustsendaddress
        : "";
      this.entrustInfoForm.entrustsendemail = order.entrustsendemail
        ? order.entrustsendemail
        : "";

      //报告信息
      this.reportInfoForm.reportsendpsn = order.reportsendpsn
        ? order.reportsendpsn
        : "";
      this.reportInfoForm.reportsendtel = order.reportsendtel
        ? order.reportsendtel
        : "";
      this.reportInfoForm.reportsendaddress = order.reportsendaddress
        ? order.reportsendaddress
        : "";
      this.reportInfoForm.reportsendemail = order.reportsendemail
        ? order.reportsendemail
        : "";

      this.orderId = order.id; //订单ID
      this.updateDDL = false; //修改功能首次不更新动态字段
    },
    // 获取产品列表
    async handleListProduct() {
      const res = await listProduct();
      this.fillTestInfoForm.productList.splice(
        0,
        this.fillTestInfoForm.productList.length,
        ...res
      );
    },
  },
  async created() {
    await Promise.all([this.handleListProduct(), this.getQuery()]);
    this.handleCopyInitForm();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/elementuiReset.scss";
.create_wrap {
  background: #ffffff;
  border-radius: 3px;
  padding: 0 10%;
  min-height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .create {
    // padding: 0 20px;
    flex-grow: 1;
  }
  .submit {
    display: flex;
    align-items: center;
    padding: 30px 0 60px 0;
  }
}
</style>
