<template>
  <div class="fillDemoInfo_wrap">
    <p class="title">样本信息</p>
    <el-form
      :model="myData"
      :rules="fillDemoInfoRules"
      ref="fillDemoInfoForm"
      label-width="100px"
      class="fillDemoInfo_form"
      :label-position="labelPosition"
    >
      <el-form-item label="样本类型" prop="demoType">
        <el-select
          v-model="myData.demoType"
          placeholder="请选择"
          style="width: 100%"
          :disabled="myDisabled"
        >
          <!-- <el-option label="小肽类" value="小肽类"></el-option> //这个没有
          <el-option label="重组蛋白" value="重组蛋白"></el-option>
          <el-option label="单抗类" value="单抗类"></el-option>//这个没有
          <el-option label="其他类" value="其他类"></el-option> //这个没有-->
          <el-option :label="item.name" :value="item.value" :key="index" v-for="(item, index) of sampleTypes"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="样本来源" prop="demoOrigin">
        <el-select
          v-model="myData.demoOrigin"
          placeholder="请选择"
          style="width: 100%"
          :disabled="myDisabled"
        >
          <el-option label="生产" value="生产"></el-option>
          <el-option
            label="购买（请提供原包装信息）"
            value="购买（请提供原包装信息）"
          ></el-option>
          <el-option
            label="其他（备注里请明确）"
            value="其他（备注里请明确）"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="样本名称" prop="demoName">
        <el-input
          v-model="myData.demoName"
          placeholder="请填写"
          style="width: 100%"
          :disabled="myDisabled"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="样本批号" prop="demoNo">
        <el-input
          v-model="myData.demoNo"
          placeholder="请填写"
          style="width: 100%"
          :disabled="myDisabled"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="样本数量" prop="demoAmount">
        <el-input-number
          v-model="myData.demoAmount"
          placeholder="请填写"
          style="width: 20%"
          controls-position="right"
          :disabled="myDisabled"
          :min="1"
          :step="1"
          step-strictly
        >
        </el-input-number>
      </el-form-item>
      <el-form-item label="样本性状" prop="demoShape">
        <el-select
          v-model="myData.demoShape"
          placeholder="请选择"
          style="width: 100%"
          :disabled="myDisabled"
        >
          <el-option label="无色澄清液体" value="无色澄清液体"></el-option>
          <el-option label="淡黄色澄清液体" value="淡黄色澄清液体"></el-option>
          <el-option label="白色固体粉末" value="白色固体粉末"></el-option>
          <el-option label="类白色固体粉末" value="类白色固体粉末"></el-option>
          <el-option label="其他" value="其他"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="纯度" prop="purity">
        <el-input
          v-model.trim="myData.purity"
          placeholder="请填写"
          style="width: 20%"
          :disabled="myDisabled"
        >
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
      <el-form-item label="样本有效期至" prop="endDate">
        <el-date-picker
          v-model="myData.endDate"
          type="date"
          placeholder="选择日期"
          style="width: 20%"
          :disabled="myDisabled"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="样本规格" prop="demoStandard">
        <el-select
          v-model="myData.demoStandard"
          placeholder="请选择"
          style="width: 100%"
          :disabled="myDisabled"
        >
          <el-option label="原料药" value="原料药"></el-option>
          <el-option label="化合物" value="化合物"></el-option>
          <el-option label="成品药" value="成品药"></el-option>
          <el-option label="中间体" value="中间体"></el-option>
          <el-option label="其他" value="其他"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="贮存条件" prop="saveCondition">
        <el-select
          v-model="myData.saveCondition"
          placeholder="请选择"
          style="width: 100%"
          :disabled="myDisabled"
        >
          <el-option label="室温" value="室温"></el-option>
          <el-option label="2-8℃" value="2-8℃"></el-option>
          <el-option label="-15至-25℃" value="-15至-25℃"></el-option>
          <el-option label="-65至-85℃" value="-65至-85℃"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="样本包装" prop="demoPack">
        <el-select
          v-model="myData.demoPack"
          placeholder="请选择"
          style="width: 100%"
          :disabled="myDisabled"
        >
          <el-option label="离心管" value="离心管"></el-option>
          <el-option label="玻璃瓶" value="玻璃瓶"></el-option>
          <el-option label="塑料容器" value="塑料容器"></el-option>
          <el-option label="其他" value="其他"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="样本状态" prop="demoState">
        <el-select
          v-model="myData.demoState"
          placeholder="请选择"
          style="width: 100%"
          :disabled="myDisabled"
          @change="changeDemoState"
        >
          <el-option label="固体" value="固体"></el-option>
          <el-option label="液体" value="液体"></el-option>
          <el-option label="凝胶" value="凝胶"></el-option>
          <el-option label="PVDF膜" value="PVDF膜"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="重量:"
        prop="weight"
        v-if="myData.demoState === '固体'"
      >
        <el-input
          v-model.trim="myData.weight"
          style="width: 100%"
          :disabled="myDisabled"
          placeholder="请填写"
        >
          <span slot="append">mg</span>
        </el-input>
      </el-form-item>
      <template v-else-if="myData.demoState === '液体'">
        <el-form-item label="浓度" prop="concentration">
          <el-input
            v-model.trim="myData.concentration"
            style="width: 100%"
            :disabled="myDisabled"
            placeholder="请填写"
          >
            <span slot="append">mg/ml</span>
          </el-input>
        </el-form-item>
        <el-form-item label="体积" prop="volume">
          <el-input
            v-model.trim="myData.volume"
            style="width: 100%"
            :disabled="myDisabled"
            placeholder="请填写"
          >
            <span slot="append">ml</span>
          </el-input>
        </el-form-item>
      </template>
      <el-form-item
        label="凝胶类型"
        prop="demoState"
        v-else-if="myData.demoState === '凝胶'"
      >
        <el-select
          v-model="myData.gel"
          placeholder="请选择"
          style="width: 100%"
          :disabled="myDisabled"
        >
          <el-option label="1D银染" value="1DYR"></el-option>
          <el-option label="1D考染" value="1DKR"></el-option>
          <el-option label="2D银染" value="2DYR"></el-option>
          <el-option label="2D考染" value="2DKR"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="PVDF膜"
        prop="pvdf"
        v-else-if="myData.demoState === 'PVDF膜'"
      >
        <el-select
          v-model="myData.pvdf"
          placeholder="请选择类型"
          style="width: 100%"
          :disabled="myDisabled"
        >
          <el-option label="考染" value="KR"></el-option>
          <el-option label="丽春红" value="LCH"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="实验备注"
        prop="experimentalRequirements"
        class="product_wrap"
      >
        <el-input
          type="textarea"
          :rows="4"
          v-model="myData.experimentalRequirements"
          placeholder="请填写备注"
          style="width: 100%"
          :disabled="myDisabled"
          class="remark"
          maxlength="500"
          show-word-limit
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import utils from "@/utils/index.js";
import { order } from "@/utils/dict.js";
//校验是否大于0的数字
const isGreaterThanZero = (rule, value, cb) => {
  if (utils.validate.isGreaterThanZero(value)) {
    cb();
  } else {
    cb(new Error("请填写大于0的数字"));
  }
};

export default {
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      labelPosition: "top",
      myData: this.data,
      //样本信息校验规则
      fillDemoInfoRules: {
        demoType: [
          {
            required: true,
            message: "请选择样本类型",
            trigger: "blur",
          },
        ],
        demoOrigin: [
          {
            required: true,
            message: "请选择样本来源",
            trigger: "blur",
          },
        ],
        demoName: [
          {
            required: true,
            message: "请填写样本名称",
            trigger: "blur",
          },
        ],
        demoNo: [
          {
            required: true,
            message: "请填写样本批号",
            trigger: "blur",
          },
        ],
        demoAmount: [
          {
            required: true,
            message: "请填写样本数量",
            trigger: "blur",
          },
        ],
        demoShape: [
          {
            required: true,
            message: "请填写样本性状",
            trigger: "blur",
          },
        ],
        purity: [
          {
            required: true,
            message: "请填写纯度",
            trigger: "blur",
            validator: isGreaterThanZero,
          },
        ],
        endDate: [
          {
            required: true,
            message: "请选择截止有效期",
            trigger: "blur",
          },
        ],
        demoStandard: [
          {
            required: true,
            message: "请选择样本规格",
            trigger: "blur",
          },
        ],
        saveCondition: [
          {
            required: true,
            message: "请填写贮存条件",
            trigger: "blur",
          },
        ],
        demoPack: [
          {
            required: true,
            message: "请选择样本包装",
            trigger: "blur",
          },
        ],
        demoState: [
          {
            required: true,
            message: "请选择样本状态",
            trigger: "blur",
          },
        ],
        weight: [
          {
            required: true,
            trigger: "blur",
            validator: isGreaterThanZero,
          },
        ],
        concentration: [
          {
            required: true,
            trigger: "blur",
            validator: isGreaterThanZero,
          },
        ],
        volume: [
          {
            required: true,
            trigger: "blur",
            validator: isGreaterThanZero,
          },
        ],
        gel: [
          {
            required: true,
            message: "请选择凝胶类型:",
            trigger: "blur",
          },
        ],
        pvdf: [
          {
            required: true,
            message: "请选择PVDF膜类型:",
            trigger: "blur",
          },
        ],
      },
      //样本类型
      sampleTypes: order.sampleTypes 
    };
  },
  computed: {
    myDisabled() {
      return this.disabled;
    },
  },
  methods: {
    async validate() {
      let result = undefined;
      await this.$refs.fillDemoInfoForm.validate((valid) => {
        result = valid;
      });
      return result;
    },
    async validateField(params) {
      //params: array | string，需要校验的字段,不填则全部校验
      const result = await new Promise((resolve) => {
        if (params.length) {
          this.$refs.fillDemoInfoForm.validateField(params, (errMsg) => {
            resolve(errMsg);
          });
        } else {
          resolve();
        }
      });
      return result;
    },
    resetFields() {
      this.$refs.fillDemoInfoForm.resetFields();
      this.myData.detectionFields = [];
      this.myData.dynamicDataList = [];
      this.myData.preDynamicDataList = [];
      this.myData.demoState = "";
      this.myData.gel = "";
    },
    changeDemoState(val) {
      const demoStateList = [
        {
          value: "固体",
          key: ["weight"],
        },
        {
          value: "液体",
          key: ["concentration", "volume"],
        },
        {
          value: "凝胶",
          key: ["gel"],
        },
        {
          value: "固体",
          key: ["pvdf"],
        },
      ];
      demoStateList.forEach((item) => {
        if (item.value === val) {
          this.fillDemoInfoRules[item.key[0]][0].required = true;
          if (item.key[1]) {
            this.fillDemoInfoRules[item.key[1]][0].required = true;
          }
        } else {
          this.myData[item.key[0]] = "";
          this.fillDemoInfoRules[item.key[0]][0].required = false;
          if (item.key[1]) {
            this.myData[item.key[1]] = "";
            this.fillDemoInfoRules[item.key[1]][0].required = false;
          }
        }
      });
      if (val === "凝胶") {
        this.myData.gel = "1DYR";
      }
      if (val === "PVDF膜") {
        this.myData.pvdf = "KR";
      }
      //改变状态后，清空上次的校验信息
      const clearValidateList = demoStateList.map((item) => item.key).flat();
      this.$refs.fillDemoInfoForm.clearValidate(clearValidateList);
    },
  },
};
</script>

<style lang="scss" scoped>
.fillDemoInfo_wrap {
  .title {
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: 600;
  }
}

/*样式穿透*/
.fillDemoInfo ::v-deep .fillDemoInfo_form {
  display: flex;
  flex-wrap: wrap;

  .product_wrap {
    width: 100%;
  }

  .add {
    font-size: 28px;
    color: #8c939d;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
  }

  .product_wrap {
    position: relative;

    .product_introduce {
      display: flex;
      flex-grow: 1;
    }
  }

  .TheortSeqImg {
    .el-upload-list--picture-card,
    .el-upload-list__item,
    .el-upload--picture-card {
      width: 94px;
      height: 94px;
    }

    .el-upload--picture-card {
      line-height: 94px;
    }
  }

  .remark {
    .el-textarea__inner {
      height: 72px;
    }
  }

  .submit {
    display: flex;
    width: 100%;
    margin-top: 20px;
  }

  .el-input__inner {
    height: 36px;
    line-height: 36px;
  }
  .el-input-number {
    line-height: 34px;
  }
}
</style>
