<template>
  <div class="order elTab">
    <bc-table :data="tableData" ref="bcTable" @validate="validate" />
    <div class="footer">
      <el-button style="height: 36px; min-width: 96px;" type="primary" @click="handleSubmitForm"
        >提 交</el-button
      >
      <!-- <el-button type="primary" plain @click="dialogEntrust = true"
        >委托协议模板</el-button
      > -->
      <el-button style="height: 36px; min-width: 96px;" plain @click="resetForm">重置</el-button>
      <el-button style="height: 36px; min-width: 96px;" plain @click="saveToDraft"
        >保存至草稿箱</el-button
      >
      <!-- <el-button plain @click="$emit('back')" style="margin-left: 34px"
        >返回</el-button
      > -->
      <el-button
        style="height: 36px; min-width: 96px;"
        type="primary"
        plain
        @click.native="createOrder"
        >新增一行</el-button
      >
    </div>
    <apt-entrust-protocol v-model="dialogEntrust" />
    <!-- <el-dialog
      title="委托协议确认"
      top="68px"
      center
      :modal="false"
      custom-class="dialogEntrust"
      :visible.sync="dialogEntrust"
      width="600px"
    >
      <el-table :data="tableData1" style="width: 100%" border>
        <el-table-column prop="date" label="序号" width="120" align="center">
        </el-table-column>
        <el-table-column prop="name" label="产品名称" align="center">
        </el-table-column>
        <el-table-column
          prop="address"
          label="委托协议"
          width="120"
          align="center"
        >
          <el-button type="text" @click="preview">预览</el-button>
          <el-button type="text" @click="download">下载</el-button>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-button type="primary" @click="handleSubmitForm">确定</el-button>
        <el-button plain @click="dialogEntrust = false" class="close"
          >关闭</el-button
        >
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import bcTable from "./bcTable.vue";
import { submitDraft, saveDraft } from "@/api/order/draft";
export default {
  name: "order",
  components: {
    bcTable,
  },
  data() {
    return {
      active: "/order/batchCreate",
      tableData: [],
      dialogEntrust: false, //确认委托协议弹窗
      // 默认的一行订单数据
      defaultOrderData: {
        //检测信息
        fillTestInfoForm: {
          product: "", //产品
          requirement: "", //检测要求
          detectionFields: [], //产品动态检测信息字段(查询数据)
          dynamicDataList: [], //产品动态检测信息字段(用户填写)
          reportVersion: "", //报告版本
          reportMedium: "", //报告介质
          reportLanguage: "中文", //报告语言
          testType: "", //检测类别
          postType: "", //送样方式
          handleRemain: "", //余样处理
          hasRemain: "", //余样处理
          remark: "", //备注
        },
        //样品信息
        fillDemoInfoForm: {
          demoType: "", //样品类型
          demoOrigin: "", //样品来源
          demoName: "", //样品名称
          demoNo: "", //样品批号
          demoAmount: "", //样品数量
          demoShape: "", //样品性状
          purity: "", //纯度
          endDate: "", //截止有效期
          demoStandard: "", //样品规格
          storeCondition: "", //保存条件
          demoPack: "", //样品包装
          demoState: "", //样品状态
          weight: "", //重量
          concentration: "", //浓度
          volume: "", //体积
          gel: "", //凝胶类型
          pvdf: "", //PVDF膜类型
          experimentalRequirements: "", //实验备注
        },
        //检测信息校验规则
        fillTestInfoRules: {
          product: [
            { required: true, message: "请选择产品", trigger: "change" },
          ],
          reportVersion: [
            { required: true, message: "请选择报告版本", trigger: "blur" },
          ],
          reportMedium: [
            { required: true, message: "请选择报告介质", trigger: "blur" },
          ],
          reportLanguage: [
            { required: true, message: "请选择报告语言", trigger: "blur" },
          ],
          testType: [
            { required: true, message: "请选择检测类别", trigger: "blur" },
          ],
          postType: [
            { required: true, message: "请选择送样方式", trigger: "blur" },
          ],
          handleRemain: [
            {
              required: true,
              message: "请选择余样处理方式",
              trigger: "blur",
            },
          ],
          hasRemain: [
            { required: true, message: "请选择是否留样", trigger: "blur" },
          ],
        },
        //样品信息校验规则
        fillDemoInfoRules: {
          demoType: [
            { required: true, message: "请选择样品类型", trigger: "blur" },
          ],
          demoOrigin: [
            { required: true, message: "请填写样品来源", trigger: "blur" },
          ],
          demoName: [
            { required: true, message: "请填写样品名称", trigger: "blur" },
          ],
          demoNo: [
            { required: true, message: "请填写样品批号", trigger: "blur" },
          ],
          demoAmount: [
            { required: true, message: "请填写样品数量", trigger: "blur" },
          ],
          demoShape: [
            { required: true, message: "请填写样品性状", trigger: "blur" },
          ],
          purity: [{ required: true, message: "请填写纯度", trigger: "blur" }],
          endDate: [
            { required: true, message: "请选择截止有效期", trigger: "blur" },
          ],
          demoStandard: [
            { required: true, message: "请选择样品规格", trigger: "blur" },
          ],
          storeCondition: [
            { required: true, message: "请填写保存条件", trigger: "blur" },
          ],
          demoPack: [
            { required: true, message: "请选择样品包装", trigger: "blur" },
          ],
          demoState: [
            { required: true, message: "请选择样品状态", trigger: "blur" },
          ],
          weight: [
            {
              required: true,
              message: "请填写重量(单位/mg)",
              trigger: "blur",
            },
          ],
          concentration: [
            {
              required: true,
              message: "请填写浓度(单位：mg/ml):",
              trigger: "blur",
            },
          ],
          volume: [
            {
              required: true,
              message: "请填写体积(单位：ml):",
              trigger: "blur",
            },
          ],
          gel: [
            { required: true, message: "请选择凝胶类型:", trigger: "blur" },
          ],
          pvdf: [
            { required: true, message: "请选择PVDF膜类型:", trigger: "blur" },
          ],
        },
        //索引
        index: 0,
        //其他信息
        otherInfoForm: {
          urgent: "N", //是否加急,N-加急，Y-不加急
        },
        //其他信息校验规则
        otherInfoRules: {
          urgent: [
            { required: true, message: "请确定是否加急", trigger: "blur" },
          ],
        },
      },
    };
  },
  methods: {
    handleClick(tab, event) {
      this.$router.push(`${this.active}`);
    },
    //选中报告选项
    handleCommand(row) {
      console.log(row);
    },
    //保存草稿
    saveToDraft() {
      const requestList = this.tableData.map((item) =>
        saveDraft(this.getParams(item))
      );
      Promise.all(requestList).then(() => {
        this.$message({
          type: "success",
          message: "保存至草稿箱已成功",
        });
      });
    },
    resetForm() {
      this.initTableData();
    },
    //新增订单
    createOrder() {
      if (this.tableData.length >= 10) {
        this.$message({
          type: "warning",
          message: "批量新建最多支持10条数据",
        });
        return;
      }
      const defaultOrderData = JSON.parse(
        JSON.stringify(this.defaultOrderData)
      );
      const obj = {
        ...defaultOrderData,
        index: this.tableData.length,
      };
      this.tableData.push(obj);
    },
    //加载提交订单
    handleSubmitForm() {
      this.$refs.bcTable.validate();
    },
    //预览
    preview() {},
    //下载
    download() {},
    validate(valid) {
      if (!valid) return;
      this.submitForm();
    },
    //提交订单
    async submitForm() {
      const requestList = this.tableData.map((item) =>
        submitDraft(this.getParams(item))
      );
      Promise.all(requestList).then(() => {
        this.$message({
          type: "success",
          message: "提交订单已成功",
        });
        this.$refs.bcTable.dialogOperation = false;
      });
    },
    // 获取提交草稿或订单的参数
    getParams(item) {
      const dynamicDataList = JSON.parse(
        JSON.stringify(item.fillTestInfoForm.dynamicDataList)
      );
      dynamicDataList.forEach((item) => {
        delete item.fileList;
      });
      const params = {
        batchNo: item.fillDemoInfoForm.demoNo, //样品批号
        concentration: item.fillDemoInfoForm.concentration, //浓度；单位：mg/ml
        count: item.fillDemoInfoForm.demoAmount, //样品数量
        dynamicDataList, //检测信息动态字段
        expiryDate: item.fillDemoInfoForm.endDate, //有效期
        gelType:
          item.fillDemoInfoForm.demoState === "凝胶"
            ? item.fillDemoInfoForm.gel
            : "", //凝胶类型； 1：1D银染。2：1D考染。3：2D银染。4：2D考染
        isKeepingSample: item.fillTestInfoForm.hasRemain, //是否留样；1：不留样。2：留样（委托方提供一式三份样品，独立包装，现场封签；否则，仅对此次测试负责）
        orderId: item.orderId ? item.orderId : undefined, //订单ID
        productId: item.fillTestInfoForm.product
          ? item.fillTestInfoForm.product
          : undefined, //产品id
        purity: item.fillDemoInfoForm.purity, //纯度
        pvdf:
          item.fillDemoInfoForm.demoState === "PVDF膜"
            ? item.fillDemoInfoForm.pvdf
            : "", //PVDF膜；1：考染。2：丽春红
        remark: item.fillTestInfoForm.remark, //备注
        reportLanguage: item.fillTestInfoForm.reportLanguage, //报告语言；1：中文。
        reportMedium: item.fillTestInfoForm.reportMedium, //报告介质；1：电子版。2：纸质版。3：两者皆需要。
        reportVersion: item.fillTestInfoForm.reportVersion, //报告版本；1：CMA报告+APT报告。2：中科新生命版 (APT版)。
        residualSampleDispose: item.fillTestInfoForm.handleRemain, //余样处理；1：不退样。2：退样
        sampleName: item.fillDemoInfoForm.demoName, //样品名称
        samplePackage: item.fillDemoInfoForm.demoPack, //样品包装；1：离心管。2：玻璃瓶。3：塑料容器。4：其他。
        sampleSendMethod: item.fillTestInfoForm.postType, //送样方式；1：物流送样。2：客户送样。3：委托抽样（需另外支付费用）。
        sampleSource: item.fillDemoInfoForm.demoOrigin, //样品来源；1：生产。2：购买（请提供原包装信息）。3：其他（备注里请明确）。
        sampleType: item.fillDemoInfoForm.demoType, //样品类型；1：小肽类。2：重组蛋白。3：单抗类。4：其他类。
        specs: item.fillDemoInfoForm.demoStandard, //样品规格；1：原料药。2：化合物。3：成品药。4：中间体。5：其他。
        state: item.fillDemoInfoForm.demoState, //样品状态；1：固体。2：液体。3：凝胶。4：PVDF膜。
        storageConditions: item.fillDemoInfoForm.storeCondition, //贮存条件；1：室温。2：2-8℃。3：-15至-25℃。4：-65至-85℃
        testType: item.fillTestInfoForm.testType, //检测类别；1：委托。2：出口。3：复核。4：其他。
        trait: item.fillDemoInfoForm.demoShape, //样品性状；1：无色澄清液体。2：淡黄色澄清液体。3：白色固体粉末。4：类白色固体粉末。5：其他。
        volume:
          item.fillDemoInfoForm.demoState === "液体"
            ? item.fillDemoInfoForm.volume
            : undefined, //体积；单位：ml
        weight:
          item.fillDemoInfoForm.demoState === "固体"
            ? item.fillDemoInfoForm.weight
            : undefined, //重量；单位：mg
        experimentalRequirements:
          item.fillDemoInfoForm.experimentalRequirements, //实验备注
        urgent: item.otherInfoForm.urgent, //是否加急
      };
      return params;
    },
    initTableData() {
      const defaultOrderData = JSON.parse(
        JSON.stringify(this.defaultOrderData)
      );
      this.tableData.splice(0, this.tableData.length, defaultOrderData);
    },
  },
  created() {
    this.initTableData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/elementuiReset.scss";
.order {
  background: #ffffff;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 20px 40px;
  box-sizing: border-box;
  position: relative;
  .createOrder {
  }
  > .footer {
    // position: absolute;
    // bottom: 20px;
    // left: 0;
    // right: 0;
    display: flex;
    align-items: center;
    padding: 30px 0;
  }
}

/*样式穿透*/
.order ::v-deep .dialogEntrust {
  .view_wrap {
    width: 100%;
    @include end;
    .view,
    .download {
      color: $theme_color;
      font-size: 14px;
      cursor: pointer;
      margin-left: 20px;
    }
  }
  .footer {
    margin-top: 20px;
    display: flex;
    align-items: center;
    .close {
      margin-left: 20px;
    }
  }
}
</style>
