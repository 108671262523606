import _ from "lodash";

/**
 * @param {string} username
 * @returns {Boolean}
 * @desc 验证用户名是否有效
 */
function validUsername(username) {
  return /^[a-zA-Z0-9_-]{4,16}$/.test(username);
}

/**
 * @param {string} password
 * @returns {Boolean}
 * @desc 验证密码是否有效
 */
function validPassword(password) {
  return /^\S*(?=\S{6,})(?=\S*\d)(?=\S*[A-Za-z])\S*$/.test(password);
}

/**
 * @param {string} str
 * @returns {Boolean}
 * 是否为数字类型的字符串
 */
function isNumberString(str) {
  return /^[0-9]+((.?[0-9]+$)|([0-9]*$))/.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 * 是否为手机号（宽松）
 */
function isMobile(str) {
  return /^(?:(?:\+|00)86)?1\d{10}$/.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 * 是否为固话（宽松）
 */
function isTel(str) {
  return /^(\d{3,4}-)?\d{7,8}(-\d{1,4})?$/.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 * 是否为邮箱（支持中文邮箱）
 */
function isEmail(str) {
  return /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
    str
  );
}

/**
 * @param {string} str
 * @returns {Boolean}
 * 是否为大于0的数字
 */
function isGreaterThanZero(str) {
  return /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/.test(str);
}

export default {
  validUsername,
  validPassword,
  isNumberString,
  isMobile,
  isTel,
  isEmail,
  isGreaterThanZero,
};
