export default {
  namespaced: true,
  state: {
    createOrderStep: 0,
  },
  mutations: {
    changeCreateOrderStep(state, params) {
      state.createOrderStep = params;
    }
  },
  actions: {
    changeCreateOrderStep(context, params) {
      context.commit("changeCreateOrderStep", params);
    },
  },
  getters: {
    getCreateOrderStep(state) {
      return state.createOrderStep;
    },
  },
};
