/**
 * 字典
 */
// 全局css变量
const css = {
  themeColor: "#1890ff",
};
// 订单模块
const order = {
  //订单状态
  status: [
    // 订单筛选状态定义
    {
      name: "全部",
      value: -1,
    },
    // 订单描述状态定义
    {
      name: "待提交",
      value: 1,
      color: "#1890FF",
      bgColor: "rgba(236, 245, 255, 1)",
      bdColor: "rgba(217, 236, 255, 1)",
    },
    {
      name: "已删除",
      value: 2,
      color: "#1890FF",
      bgColor: "rgba(236, 245, 255, 1)",
      bdColor: "rgba(217, 236, 255, 1)",
    },
    {
      name: "订单提交成功，待审核",
      value: 11,
      color: "#1890FF",
      bgColor: "rgba(236, 245, 255, 1)",
      bdColor: "rgba(217, 236, 255, 1)",
    },
    {
      name: "送样中",
      value: 12,
      color: "#1890FF",
      bgColor: "rgba(236, 245, 255, 1)",
      bdColor: "rgba(217, 236, 255, 1)",
    },
    {
      name: "执行中",
      value: 13,
      color: "#1890FF",
      bgColor: "rgba(236, 245, 255, 1)",
      bdColor: "rgba(217, 236, 255, 1)",
    },
    {
      name: "已终止",
      value: 14,
      color: "#F56C6C",
      bgColor: "rgba(254, 240, 240, 1)",
      bdColor: "rgba(253, 226, 226, 1)",
    },
    {
      name: "结果待验收",
      value: 15,
      color: "#1890FF",
      bgColor: "rgba(236, 245, 255, 1)",
      bdColor: "rgba(217, 236, 255, 1)",
    },
    {
      name: "结果验收未通过，售后待受理",
      value: 16,
      color: "#E6A23C",
      bgColor: "rgba(253, 246, 236, 1)",
      bdColor: "rgba(250, 236, 216, 1)",
    },
    {
      name: "结果验收未通过，售后处理中",
      value: 17,
      color: "#E6A23C",
      bgColor: "rgba(253, 246, 236, 1)",
      bdColor: "rgba(250, 236, 216, 1)",
    },
    {
      name: "订单审核未通过",
      value: 20,
      color: "#F56C6C",
      bgColor: "rgba(254, 240, 240, 1)",
      bdColor: "rgba(253, 226, 226, 1)",
    },
    {
      name: "订单提交成功，加急申请待审核",
      value: 21,
      color: "#1890FF",
      bgColor: "rgba(236, 245, 255, 1)",
      bdColor: "rgba(217, 236, 255, 1)",
    },
    {
      name: "订单审核未通过，加急申请被驳回",
      value: 23,
      color: "#F56C6C",
      bgColor: "rgba(254, 240, 240, 1)",
      bdColor: "rgba(253, 226, 226, 1)",
    },
    {
      name: "执行中，加急申请待审核",
      value: 24,
      color: "#1890FF",
      bgColor: "rgba(236, 245, 255, 1)",
      bdColor: "rgba(217, 236, 255, 1)",
    },
    {
      name: "执行中，加急申请通过",
      value: 25,
      color: "#1890FF",
      bgColor: "rgba(236, 245, 255, 1)",
      bdColor: "rgba(217, 236, 255, 1)",
    },
    {
      name: "执行中，加急申请未通过",
      value: 26,
      color: "#E6A23C",
      bgColor: "rgba(253, 246, 236, 1)",
      bdColor: "rgba(250, 236, 216, 1)",
    },
    {
      name: "结果验收通过",
      value: 27,
      color: "#1890FF",
      bgColor: "rgba(236, 245, 255, 1)",
      bdColor: "rgba(217, 236, 255, 1)",
    },
    {
      name: "售后申请未受理，结果待验收",
      value: 28,
      color: "#E6A23C",
      bgColor: "rgba(253, 246, 236, 1)",
      bdColor: "rgba(250, 236, 216, 1)",
    },
    {
      name: "已完成",
      value: 29,
      color: "#67C23A",
      bgColor: "rgba(240, 249, 235, 1)",
      bdColor: "rgba(225, 243, 216, 1)",
    },
    {
      name: "确认单提交成功，待审核",
      value: 30,
      color: "#1890FF",
      bgColor: "rgba(236, 245, 255, 1)",
      bdColor: "rgba(217, 236, 255, 1)",
    },
    // {
    //   name: "报告发放中",
    //   value: 18,
    //   color: "#1890FF",
    //   bgColor: "rgba(236, 245, 255, 1)",
    //   bdColor: "rgba(217, 236, 255, 1)",
    // },
    // {
    //   name: "报告已发放",
    //   value: 19,
    //   color: "#67C23A",
    //   bgColor: "rgba(240, 249, 235, 1)",
    //   bdColor: "rgba(225, 243, 216, 1)",
    // },
  ],
  //二级订单状态
  status2: [
    // 订单筛选状态定义
    {
      name: "全部",
      value: "-1",
    },
    {
      name: "订单提交成功，待审核", //针对单一状态值
      value: "11",
    },
    {
      name: "订单审核未通过", //针对单一状态值
      value: "20",
    },
    {
      name: "订单提交成功，加急待审核", //针对单一状态值
      value: "21",
    },
    {
      name: "订单加急审核未通过", //针对单一状态值
      value: "23",
    },
    {
      name: "送样中", //针对单一状态值
      value: "12",
    },
    {
      name: "执行中", //包括状态值（13,24,25,26）
      value: "13,24,25,26",
    },
    {
      name: "结果待验收", //包括状态值（15,28）
      value: "15,28",
    },
    {
      name: "报告售后中", //包括状态值（16,17）
      value: "16,17",
    },
    {
      name: "结果验收通过", //针对单一状态值
      value: "27",
    },
    {
      name: "已完成", //针对单一状态值
      value: "29",
    },
    {
      name: "已终止", //针对单一状态值
      value: "14",
    },
    //针对ltc额外增加状态值
    {
      name: "结果待验收", //LTC暂无售后
      value: "15",
    },
    {
      name: "确认单提交成功，待审核", //针对单一状态值
      value: "30",
    },
    {
      name: "报告打印待申请", //针对单一状态值
      value: "31",
    },
    {
      name: "报告可下载", //针对单一状态值
      value: "32",
    },
    {
      name: "待评价", //针对单一状态值
      value: "33",
    },
  ],
  //订单查询参数
  queryParams: [
    //样本名称、订单号、合同号、方案号、产品名称、标题、样本编号
    {
      name: "产品名称",
      value: "productName",
    },
    {
      name: "样本名称",
      value: "sampleName",
    },
    {
      name: "订单编号",
      value: "bpOrderNo",
    },
    // {
    //   name: "合同号",
    //   value: "contractNo",
    // },
    // {
    //   name: "方案号",
    //   value: "planNo",
    // },
    // {
    //   name: "标题",
    //   value: "title",
    // },
    // {
    //   name: "样本编号",
    //   value: "sampleNo",
    // },
  ],
  //样本填写数据
  demo: {
    //样本来源
    demoOrigin: [
      {
        name: "生产",
        value: "生产",
      },
      {
        name: "购买（请提供原包装信息）",
        value: "购买（请提供原包装信息）",
      },
      {
        name: "其他（备注里请明确）",
        value: "其他（备注里请明确）",
      },
    ],
  },
  //业务类型
  busiType: {
    bsgOtd: "21", //BSG-OTD
    bsgLtc: "22", //BSG-LTC
    bsgNew: "23", //BSG-NEW（0~1）
    asgLtc: "12", //ASG-LTC
    asgNew: "13", //ASG-NEW（0~1）
    asgIc: "15", //ASG-IC（工业渠道）
  },
  //业务类型对象数据
  bstpObj: {
    bsgOtd: {
      bstp: "bsgOtd",
      menu: "我的订单-OTD",
      value: "21",
      route: "/bsg/otd/order",
    },
    bsgLtc: {
      bstp: "bsgLtc",
      menu: "我的订单-LTC",
      value: "22",
      route: "/bsg/ltc/order",
    },
    asgLtc: {
      bstp: "asgLtc",
      menu: "我的订单-LTC",
      value: "12",
      route: "/asg/ltc/order",
    },
  },
  //业务类型列表
  bstpList: [
    {
      bstp: "bsgOtd",
      menu: "我的订单-OTD",
      value: "21",
      route: "/bsg/otd/order",
    },
    {
      bstp: "bsgLtc",
      menu: "我的订单-LTC",
      value: "22",
      route: "/bsg/ltc/order",
    },
    {
      bstp: "asgLtc",
      menu: "我的订单-LTC",
      value: "12",
      route: "/asg/ltc/order",
    },
  ],
  //备注状态阶段列表;/
  remarkProcessList: [
    {
      name: "查看备注",
      value: [20, 15],
    },
    {
      name: "查看物流",
      value: [12, 19],
    },
  ],
  //草稿查询参数
  draftQueryParams: [
    //样本名称、产品名称
    {
      name: "样本名称",
      value: "sampleName",
    },
    {
      name: "产品名称",
      value: "productName",
    },
  ],
  //服务评分
  serviceScore: [
    {
      label: "0",
      name: "0",
    },
    {
      label: "1",
      name: "1",
    },
    {
      label: "2",
      name: "2",
    },
    {
      label: "3",
      name: "3",
    },
    {
      label: "4",
      name: "4",
    },
    {
      label: "5",
      name: "5",
    },
    {
      label: "6",
      name: "6",
    },
    {
      label: "7",
      name: "7",
    },
    {
      label: "8",
      name: "8",
    },
    {
      label: "9",
      name: "9",
    },
    {
      label: "10",
      name: "10",
    },
  ],
  //体验评分
  experienceScore: [
    {
      label: "7",
      name: "7",
    },
    {
      label: "6",
      name: "6",
    },
    {
      label: "5",
      name: "5",
    },
    {
      label: "4",
      name: "4",
    },
    {
      label: "3",
      name: "3",
    },
    {
      label: "2",
      name: "2",
    },
    {
      label: "1",
      name: "1",
    },
  ],
  //推荐评分
  recommendedScore: [
    {
      label: "0",
      name: "0",
    },
    {
      label: "1",
      name: "1",
    },
    {
      label: "2",
      name: "2",
    },
    {
      label: "3",
      name: "3",
    },
    {
      label: "4",
      name: "4",
    },
    {
      label: "5",
      name: "5",
    },
    {
      label: "6",
      name: "6",
    },
    {
      label: "7",
      name: "7",
    },
    {
      label: "8",
      name: "8",
    },
    {
      label: "9",
      name: "9",
    },
    {
      label: "10",
      name: "10",
    },
  ],
	// 样本类型
	sampleTypes: [
		{
			value: "多肽药物",
			name: "多肽药物"
		},
		{
			value: "重组蛋白",
			name: "重组蛋白"
		},
		{
			value: "重组抗体",
			name: "重组抗体"
		},
		{
			value: "重组疫苗",
			name: "重组疫苗"
		},
		{
			value: "传统疫苗",
			name: "传统疫苗"
		},
		{
			value: "医美",
			name: "医美"
		},
		{
			value: "佐剂",
			name: "佐剂"
		},
		{
			value: "天然提取大分子药物",
			name: "天然提取大分子药物"
		},
		{
			value: "QC",
			name: "QC"
		},
		{
			value: "活性检测",
			name: "活性检测"
		},
		{
			value: "基因治疗",
			name: "基因治疗"
		},
		{
			value: "库检定",
			name: "库检定"
		},
		{
			value: "细胞治疗",
			name: "细胞治疗"
		},
		{
			value: "氨基酸手性",
			name: "氨基酸手性"
		},
		{
			value: "表面活性剂",
			name: "表面活性剂"
		},
		{
			value: "蛋白含量检测（质谱法/ELISA)",
			name: "蛋白含量检测（质谱法/ELISA)"
		},
		{
			value: "培养基分析",
			name: "培养基分析"
		},
		{
			value: "生物分析及其他",
			name: "生物分析及其他"
		},
		{
			value: "溶剂残留",
			name: "溶剂残留"
		},
		{
			value: "宿主蛋白/宿主DNA",
			name: "宿主蛋白/宿主DNA"
		},
		{
			value: "小分子添加物",
			name: "小分子添加物"
		},
		{
			value: "PROTAC",
			name: "PROTAC"
		},
		{
			value: "蛋白质组学",
			name: "蛋白质组学"
		},
		{
			value: "空间代谢/代谢组学",
			name: "空间代谢/代谢组学"
		},
		{
			value: "肿瘤新生抗原",
			name: "肿瘤新生抗原"
		},
		{
			value: "合成生物学",
			name: "合成生物学"
		},
		{
			value: "流式",
			name: "流式"
		},
		{
			value: "小核酸",
			name: "小核酸"
		},
		{
			value: "sgRNA",
			name: "sgRNA"
		},
		{
			value: "生物分析",
			name: "生物分析"
		},
		{
			value: "外泌体",
			name: "外泌体"
		},
		{
			value: "中药",
			name: "中药"
		},
		{
			value: "其他",
			name: "其他"
		}
	],
};

export { order, css };
