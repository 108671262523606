import request from "@/utils/request";

// 获取BSG-OTD订单列表
export function getBsgOtdOrderList(params) {
  return request({
    url: "/apt-css/order/bsg/otd/page",
    method: "get",
    params,
    timeout: 60000
  });
}

// 导出BSG-OTD订单列表
export function exportBsgOtdOrderList(params) {
  return request({
    url: "/apt-css/order/bsg/otd/export",
    method: "get",
		responseType: "blob",
		timeout: 60000,
    params
  });
}

// 获取BSG-OTD订单详情
export function getBsgOtdOrderDetail(id) {
  return request({
    url: `/apt-css/order/bsg/otd/get/${id}`,
    method: "get",
  });
}

// 获取OA订单详情
export function getOaOrderDetail(id) {
  return request({
    url: `/apt-css/order/bsg/ltc/detail/${id}`,
    method: "get",
  });
}

// 删除BSG-OTD订单
export function deleteBsgOtdOrder(id) {
  return request({
    url: `/apt-css/order/bsg/otd/delete/${id}`,
    method: "delete",
  });
}

// 复制BSG-OTD订单
export function copyBsgOtdOrder(data) {
  return request({
    url: `/apt-css/order/bsg/otd/copyOrder`,
    method: "post",
    data,
  });
}

// 获取操作列表
export function getOperationRemark(orderId) {
  return request({
    url: `/apt-css/order/record/${orderId}`,
    method: "get",
  });
}

// 获取确认报告字段参数
export function getConfirmReport(data) {
  return request({
    url: `/apt-css/order/confirmReport`,
    method: "post",
    data,
  });
}

// 提交确认报告
export function submitReportConfirmation(data) {
  return request({
    url: `/apt-css/order/submitReportConfirmation`,
    method: "post",
    data,
    timeout: 60000,
  });
}

// 下载报告
export function downloadReport(params) {
  return request({
    url: `/apt-css/order/getReportUrl`,
    method: "get",
    params,
    responseType: "blob",
  });
}

// // 下载报告
// export function downloadReport(id) {
//   return axios({
//     url: `${baseURL}/ord/report/${id}`,
//     method: "get",
//     responseType: "blob",
//     headers: {
//       Authorization: "Bearer " + getToken(),
//     },
//   });
// }

// 申请售后
export function afterSales(data) {
  return request({
    url: `/apt-css/order/afterSales`,
    method: "post",
    data,
  });
}

// 订单各状态数量
export function getOrderStatusCount(params) {
  return request({
    url: `/apt-css/order/orderStatusCount`,
    method: "get",
    params,
    timeout: 60000
  });
}

// 下载确认函
export function downloadBsgLtcConfirmPdf(id) {
  return request({
    url: `/apt-css/order/bsg/ltc/downloadConfirmPdf/${id}`,
    method: "get",
    responseType: "blob",
  });
}

// 分页查询所属订单样本列表
export function getPageSample(params) {
  return request({
    url: `/apt-css/order/bsg/ltc/pageSample`,
    method: "get",
    params,
  });
}

// 获取BSG-LTC订单列表
export function getBsgLtcOrderList(params) {
  return request({
    url: "/apt-css/order/bsg/ltc/page",
    method: "get",
    params,
    timeout: 60000
  });
}

// 获取物流信息
export function getLogistics(params) {
  return request({
    url: `/apt-css/order/bsg/otd/get/logistics/${params.id}`,
    method: "get",
    params: params.params
  });
}


