export default {
  state: {
    user: 0,
  },
  mutations: {
    setUser(state, params) {
      state.user = params;
    },
  },
  actions: {
    setUser({ commit }, params) {
      commit("setUser", params);
    },
  },
  getters: {
    getUser(state) {
      return state.user;
    },
  },
};
