/**
 * @desc 封装sessionStorage,localStorage，实现增删改查
 * @param {string} key 键
 * @param {any} val 值
 * @param {string} type 操作API类型，默认值"sessionStorage"
 */

export default {
  set(key, val, type) {
    if (type === "localStorage") {
      return localStorage.setItem(key, JSON.stringify(val));
    } else {
      return sessionStorage.setItem(key, JSON.stringify(val));
    }
  },
  get(key, type) {
    let val;
    if (type === "localStorage") {
      val = localStorage.getItem(key);
    } else {
      val = sessionStorage.getItem(key);
    }
    return JSON.parse(val);
  },
  remove(key, type) {
    if (type === "localStorage") {
      return localStorage.removeItem(key);
    } else {
      return sessionStorage.removeItem(key);
    }
  },
  clear(type) {
    if (type === "localStorage") {
      return localStorage.clear();
    } else {
      return sessionStorage.clear();
    }
  },
  setAttr(key, attr, val, type) {
    const curVal = this.get(key, type);
    curVal[attr] = val;
    if (type === "localStorage") {
      return localStorage.setItem(key, JSON.stringify(curVal));
    } else {
      return sessionStorage.setItem(key, JSON.stringify(curVal));
    }
  },
};
