<!-- 1.组件说明：tab导航 -->
<template>
  <div class="aptPageTab_container">
    <div class="aptPageTab">
      <div class="header_left">
        <div class="logo-wrap">
          <img src="@/assets/images/logo.png" class="logo" />
          <div class="title-wrap">
            <span class="title">客户支持</span>
            <span class="title-sub">Customer Support</span>
          </div>
        </div>
        <el-menu
          :default-active="active"
          class="el-menu-wrap"
          mode="horizontal"
          @select="handleClick"
          router
        >
          <!-- BSG业务类型 -->
          <el-menu-item
            class="spacing-wrap"
            :index="bstpObj.bsgOtd.route"
            v-if="user.userBstp.includes(bstpObj.bsgOtd.value)"
            >标准业务</el-menu-item
          >
          <el-menu-item
            class="spacing-wrap"
            :index="bstpObj.bsgLtc.route"
            v-if="user.userBstp.includes(bstpObj.bsgLtc.value)"
            >非标业务</el-menu-item
          >

          <!-- <el-menu-item index="/demo/demo" class="spacing-wrap">我的样本</el-menu-item> -->
          <el-menu-item
            index="/product/product"
            class="spacing-wrap"
            v-if="showProduct"
            >产品中心</el-menu-item
          >
          <!-- <el-menu-item index="/sample/sample" class="spacing-wrap">示例页面</el-menu-item> -->
        </el-menu>
      </div>
      <div class="header_right">
        <el-dropdown trigger="hover">
          <div class="right_wrap">
            <!-- <span class="profile">您好，{{ username }}</span> -->
            <span class="profile">您好，{{ company }}，{{ username }}</span>
            <img
              src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
              class="avatar"
            />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="customerInfoDialogVisible = true"
              >基本资料</el-dropdown-item
            >
            <el-dropdown-item @click.native="modifyPassword"
              >修改密码</el-dropdown-item
            >
            <!-- <el-dropdown-item @click.native="linkToSwitch()"
              >切换业务</el-dropdown-item
            > -->
            <el-dropdown-item divided @click.native="signout">
              <span>退出登录</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <modify-password v-model="openModifyPassword" />
    <el-dialog
      title="基本资料"
      :visible.sync="customerInfoDialogVisible"
      width="40%"
      :before-close="handleClose"
    >
      <el-descriptions
        class="margin-top"
        style="margin: 24px 10%"
        title=""
        :column="1"
        border
      >
        <el-descriptions-item>
          <template slot="label"> 单位名称 </template>
          {{ user.organization.orgName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 单位地址 </template>
          {{ user.organization.address }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 联系人 </template>
          {{ user.name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 手机号 </template>
          {{ user.mobile }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 邮箱 </template>
          {{ user.email }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 关联销售 </template>
          {{ user.businessTypes[0].saleMan }}
        </el-descriptions-item>
      </el-descriptions>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="customerInfoDialogVisible = false"
          >知道了</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import modifyPassword from "@/views/home/home/modifyPassword.vue";
import defaultHead from "@/assets/images/logo_khff.png";
import { order } from "@/utils/dict";
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  components: {
    modifyPassword,
  },
  data() {
    return {
      showUserInfo: false, //是否展示用户信息
      openModifyPassword: false, //是否打开修改密码弹窗
      user: null, //用户数据
      showProduct: false, //展示产品中心
      bstpList: [], //业务类型列表
      busiType: order.busiType, //字典中的业务类型
      orderBstpList: order.bstpList, //字典中的业务类型列表
      bstpObj: order.bstpObj, //字典中的业务类型对象
      customerInfoDialogVisible: false,
    };
  },
  methods: {
    // 关闭
    handleClose() {
      this.customerInfoDialogVisible = false;
    },
    // 获取当前业务类型列表
    getBstpList() {
      const list = this.user?.businessTypes;
      this.bstpList = [];
      list.map((item) => {
        this.orderBstpList.map((item2) => {
          if (item.businessType === Number(item2.value)) {
            this.bstpList.push(item2);
          }
        });
      });
    },
    // 判断是否展示产品中心tab项
    handleShowProduct() {
      if (this.user && this.user.businessTypes) {
        const bsTypes = this.user.businessTypes.map(
          (item) => `${item.businessType}`
        );
        const productType = [order.busiType.bsgOtd]; //可以显示产品中心的业务类型
        for (const item of bsTypes) {
          if (productType.includes(item)) {
            this.showProduct = true;
            break;
          }
        }
      }
    },
    // 修改密码
    modifyPassword() {
      this.openModifyPassword = true;
    },
    // 切换业务
    linkToSwitch() {
      this.$router.push("/switch/switch");
    },
    handleClick(tab, event) {
      this.$utils.storage.setAttr("user", "userBstpSelected", tab); //用户选择的业务类型
    },
    /* // 鼠标经过用户头像
			mouseenterUser() {
				this.showUserInfo = true;
			},
			// 鼠标离开用户头像
			mouseleaveUser() {
				this.showUserInfo = false;
			}, */
    // 退出登录
    signout() {
      this.$messageBox
        .confirm("此操作将退出登录, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          this.$utils.storage.remove("user");
          this.$router.push("/home");
        });
    },
    // 获取用户信息
    getUserData() {
      this.user = this.$utils.storage.get("user");
    },
  },
  computed: {
    active: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    username() {
      return this.user && this.user.name ? this.user.name : "未知";
    },
    company() {
      return this.user && this.user.organization.orgName
        ? this.user.organization.orgName
        : "未知";
    },
    userHead() {
      return this.user && this.user.avatar ? this.user.avatar : defaultHead;
    },
    userBstp() {
      return this.user?.userBstp?.split(",");
    },
  },
  watch: {
    value: {
      handler() {
        this.$router.push(`${this.active}`);
      },
      deep: true,
    },
  },
  created() {
    this.getUserData();
    this.getBstpList();
    this.handleShowProduct();
  },
};
</script>

<style lang="scss" scoped>
.aptPageTab {
  width: 100%;
  background-color: #ffffff;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 22px;

  .header_left {
    display: flex;
    flex-direction: row;
    align-items: center;

    .logo-wrap {
      margin: 14px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .logo {
        width: 36px;
        height: 36px;
      }

      .title-wrap {
        width: 160px;
        margin-left: 8px;
        display: flex;
        flex-direction: column;
        border-right: 1px solid #f0f0f0;

        .title {
          font-size: 16px;
          color: #303133;
        }

        .title-sub {
          margin-top: 4px;
          font-size: 10px;
          color: #909399;
        }
      }
    }

    .nav-wrap {
      margin-left: 48px;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14px;
      color: #606266;

      .link-wrap {
        font-size: 14px;
        color: #606266;
        text-decoration: none;

        &:hover {
          color: #3399ff;
        }
      }

      .spacing-wrap {
        margin-left: 48px;
      }
    }

    .el-menu-wrap {
      border-bottom: none;

      .spacing-wrap {
        margin-left: 16px;
        @include center;
      }

      > .el-menu-item {
        height: 64px;
      }

      .el-menu-item.is-active {
        color: #3399ff;
        border-bottom: none;
      }

      .el-menu-item:hover {
        color: #3399ff;
      }
    }
  }

  .header_right {
    .right_wrap {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;

      .profile {
        font-size: 14px;
        color: #606266;
      }

      .avatar {
        margin-left: 16px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
    }
  }
}
</style>
