import router from "@/router";
import storage from "./storage";
import { updateVersion } from "@/utils/index";

const whiteList = ["/", "/home", "/bsg/otd/preview"]; // 跳转过去的路由不需要验证白名单

// 获取登录令牌
function getToken() {
  const user = storage.get("user");
  const token = user && user.token ? user.token : undefined; // 登录令牌
  return token;
}

router.beforeEach((to, from, next) => {
  const token = getToken();
  if (whiteList.includes(from.path) || whiteList.includes(to.path) || token) {
    next();
  } else {
    next("/home");
  }
});

router.afterEach(async () => {
  await updateVersion();
});